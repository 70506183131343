import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { Modal, Menu, Row, Col } from "antd"
import { faScrewdriverWrench } from "@fortawesome/pro-solid-svg-icons"

import { useCheckForDBAdmin } from "@dbai/ui-staples"

import SpecEditor from "./SpecEditor"
import SchemaEditor from "./SchemaEditor"
import HeaderButton from "../HeaderButton"

const StyledMenu = styled(Menu)`
  width: 175px;
  height: calc(100vh - 100px);
`

const StyledCol = styled(Col)`
  height: calc(100vh - 100px);
  padding: 0 16px 16px 16px;
  overflow: auto;
`

const StyledModal = styled(Modal)`
  top: 24px;
`

const styles = {
  content: {
    padding: 0,
  },
  header: {
    padding: "20px 24px 0 24px",
  },
  body: {
    padding: "0 5px",
  },
}

const menuItems = [
  {
    key: "specEditor",
    label: "App JSON Editor",
  },
  {
    key: "schemaEditor",
    label: "Schema Editor",
  },
]

const menuContent = {
  specEditor: <SpecEditor />,
  schemaEditor: <SchemaEditor />,
}

const DevToolsMenu = () => {
  const [selected, setSelected] = useState()

  const handleMenuClick = useCallback(e => {
    setSelected(e.key)
  }, [])

  return (
    <Row wrap={false}>
      <Col>
        <StyledMenu mode="inline" items={menuItems} onClick={handleMenuClick} />
      </Col>
      <StyledCol flex={1}>
        {selected && menuContent[selected] ? menuContent[selected] : null}
      </StyledCol>
    </Row>
  )
}

const DevTools = () => {
  const [selected, setSelected] = useState(false)
  const isAdmin = useCheckForDBAdmin()

  if (!isAdmin) return null
  return (
    <>
      <HeaderButton
        icon={faScrewdriverWrench}
        title="Dev Tools"
        onClick={setSelected}
        selected={selected}
        data-testid="app-header-dev-tools-button"
      />
      <StyledModal
        styles={styles}
        width="calc(100vw - 100px)"
        title="Developer Tools"
        open={selected}
        footer={null}
        onCancel={() => setSelected(false)}
      >
        <div>
          <DevToolsMenu />
        </div>
      </StyledModal>
    </>
  )
}

export default DevTools
