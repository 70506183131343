import React from "react"
import { faWebhook } from "@fortawesome/pro-solid-svg-icons"
import { useDispatch, useSelector } from "react-redux"

import HeaderButton from "./HeaderButton"
import { actions } from "../reducers/appReducer"
import { selectShowEndpoints, selectShowEndpoint } from "../selectors/app"

const AppEndpointsButton = () => {
  const showEndpoints = useSelector(selectShowEndpoints)
  const showEndpoint = useSelector(selectShowEndpoint)
  const dispatch = useDispatch()

  const toggleShowAppFilters = () => {
    dispatch(actions.toggleShowEndpoints())
  }

  return (
    <HeaderButton
      icon={faWebhook}
      title="Endpoints"
      onClick={toggleShowAppFilters}
      selected={showEndpoints || showEndpoint}
      data-testid="app-header-endpoints-button"
    />
  )
}

export default AppEndpointsButton
