import { get } from "lodash"

import { getResolvedSchema } from "../lib/schema"

const withoutLeadingDot = str => str.replace(/^\./, "")

//checks if path ends in array
const isPathArray = path => {
  return path && path.startsWith(".[")
}

const pathToSchemaPath = name => {
  if (name === ".") return "."
  return name
    .replace(/\.[[0-9]+\]/g, "[]") // .[...] => []
    .replace(/\./g, ".properties.") // . => .properties.
    .replace(/\[\]/g, ".items") // [] => .items
}

// safely combines the path and schema key
const getNextPath = (path, schemaKey) => {
  // when schemaKey is '.', then the path is the current path, which is at the root of schema
  if (schemaKey === ".") return "."

  const pathIsRoot = path === "."
  const pathEndsWithDot = path.endsWith(".")
  const currentKeyIsArray = isPathArray(schemaKey)

  if (pathIsRoot && currentKeyIsArray) {
    return schemaKey
  }

  // The suffix combines the path and schema key by adding a dot between them
  const getSuffix = () => {
    if (pathIsRoot || pathEndsWithDot || currentKeyIsArray) {
      return schemaKey
    }

    return `.${schemaKey}`
  }

  return path ? `${path}${getSuffix()}` : getSuffix()
}

const pathToDataPath = path => {
  const dp = withoutLeadingDot(path).replace(".[", "[").replace("].", "]")
  return dp.endsWith(".") ? dp.slice(0, -1) : dp
}

const pathToInstancePath = path =>
  withoutLeadingDot(path)
    .replaceAll(".[", ".")
    .replace("].", ".")
    .replace("]", "")
    .replace(/\./g, "/")

/**
 * Given the current path and key, this function computed the next path and derives all other permutations of the
 * path from that. ex:
 * Path:
 *    .options.[0].nestedArray.[0].nestedProperty.x
 * Data path  (AKA 'name'):
 *    options[0]nestedArray[0]nestedProperty.x
 * Schema path (deprecated?):
 *    .options.items.properties.nestedArray.items.properties.nestedProperty.properties.x
 * Instance path:
 *    /options/0/nestedArray/0/nestedProperty/x
 */
const getCurrentPaths = (path, schemaKey) => {
  const nextPath = getNextPath(path, schemaKey)
  const dataPath = pathToDataPath(nextPath)
  const instancePath = pathToInstancePath(nextPath)
  return { path: nextPath, dataPath, instancePath }
}

const checkIsRequired = (parentSchema, schemaKey) => {
  const required = get(parentSchema, "required", [])
  return required.includes(schemaKey)
}

const getEnumValues = schema => {
  const { type } = schema
  if (type === "array") {
    const { enum: enumValues } = schema.items
    return enumValues
  }
  return schema.enum
}

const getSelectOptionsForSchema = schema => {
  const { metadata = {} } = schema || {}
  const enumValues = getEnumValues(schema)
  if (!enumValues) return []
  const { labels } = metadata
  return enumValues.map((value, index) => ({
    value,
    label: labels?.length ? labels[index] : value,
  }))
}

const getValueFromEvent = event => {
  const { target } = event || {}
  if (target) {
    const { type, value, checked } = target
    if (type === "checkbox") return checked
    return value
  }
  return event
}

const getItemIndexFromPath = path => {
  return path.split(".").reverse()[0].replace("]", "").replace("[", "")
}

const isSchemaHidden = (schema, formData, path) => {
  const { metadata = {} } = schema
  const { hidden } = metadata
  switch (true) {
    case typeof hidden !== "object":
      const hiddenFromSelect = schema.metadata?.hideOnSelect && formData
      return schema.metadata?.hidden || hiddenFromSelect
    case ![null, undefined].includes(hidden.itemIndex):
      const index = getItemIndexFromPath(path)
      if (parseInt(index) !== hidden.itemIndex) return false
      return true
    case Object.keys(hidden).length:
      return Object.entries(hidden).some(([key, value]) => {
        return formData[key] === value
      })
    default:
      return hidden
  }
}

const orderProperties = schema => {
  const { properties = {}, type, metadata = {} } = schema
  const { order = [] } = metadata
  if (type !== "object" || !order.length) return Object.entries(properties)

  const splitProperties = Object.entries(properties).reduce(
    (acc, [key, schema]) => {
      !order.includes(key)
        ? acc.unsortable.push([key, schema])
        : acc.sortable.push([key, schema])
      return acc
    },
    { unsortable: [], sortable: [] }
  )

  const sortedProperties = splitProperties.sortable.sort((a, b) => {
    return order.indexOf(a[0]) - order.indexOf(b[0])
  })

  return [...sortedProperties, ...splitProperties.unsortable]
}

const orderSchemaProperties = schema => {
  return orderProperties(schema).reduce(
    (acc, [key, subSchema]) => ({ ...acc, [key]: subSchema }),
    {}
  )
}

// tags - allows user to input a value or select from a list
// multiple - allows user to select multiple values from a list
const getSelectMode = schema => {
  const { metadata } = schema
  const { isMulti, allowInput, presetsSource } = metadata || {}
  const isMultiSelect = isMulti || schema.type === "array"

  // when allowInput is true, we want to allow the user to type in or select a value
  if (allowInput) return "tags"
  // when there are no presets, we want to allow the user to type in or select a value
  if (isMultiSelect && presetsSource === "none") return "tags"
  // when there are presets, we want to allow the user to select from the list
  if (isMultiSelect) return "multiple"
  return undefined
}

const splitPropertiesBySection = (properties, sections) => {
  const sectionKeys = Object.keys(sections)
  let sectionlessFields = []
  let sectionedFields = sectionKeys.reduce(
    (acc, key) => ({ ...acc, [key]: [] }),
    {}
  )
  properties.forEach(property => {
    const [key] = property
    const mappedSectionKey = sectionKeys.find(sectionKey =>
      sections[sectionKey].properties?.includes(key)
    )
    if (!mappedSectionKey) {
      sectionlessFields.push(property)
      return
    }
    sectionedFields[mappedSectionKey] = [
      ...(sectionedFields[mappedSectionKey] || []),
      property,
    ]
  })
  return [sectionedFields, sectionlessFields]
}

const deconstructObjectProperties = schema => {
  const { metadata = {} } = schema
  const { sections = {} } = metadata
  const orderedProperties = orderProperties(schema)
  const [sectioned, sectionless] = splitPropertiesBySection(
    orderedProperties,
    sections
  )
  const sectionedFields = Object.entries(sectioned)
  const sectionlessFields = sectionless
  return { sectionedFields, sectionlessFields }
}

export {
  getNextPath,
  isPathArray,
  getSelectMode,
  getEnumValues,
  isSchemaHidden,
  pathToDataPath,
  checkIsRequired,
  getCurrentPaths,
  orderProperties,
  pathToSchemaPath,
  getValueFromEvent,
  withoutLeadingDot,
  getResolvedSchema,
  orderSchemaProperties,
  deconstructObjectProperties,
  getSelectOptionsForSchema,
}
