import React, { memo, useCallback } from "react"
import styled from "styled-components"
import { Dropdown, Row, Col, Button } from "antd"
import { faPlus } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import FormList from "../../FormList"
import { useResolvedSchema, useFormFieldDropdownOptions } from "../../hooks"
import FormFieldWrapper from "../../FormFieldWrapper"

const defaultItemMapping = {
  string: "",
  number: 0,
  object: {},
  array: [],
}

const StyledDropdown = styled(Dropdown)`
  width: 100%;
`

const StyledButton = styled(Button)`
  margin-bottom: 10px;
`

const DropdownAddButton = props => {
  const { schema, metadata, parentName, schemaKey, onAppend } = props
  const { addButtonProps, addButtonText } = metadata || {}
  const options = useFormFieldDropdownOptions(schema, parentName)

  const handleAdd = useCallback(
    ({ key }) => {
      onAppend({ [schemaKey]: key })
    },
    [onAppend, schemaKey]
  )

  return (
    <StyledDropdown
      trigger={["click"]}
      placement="bottomLeft"
      menu={{
        items: options.map(option => ({
          key: option.value,
          label: option.label,
        })),
        onClick: handleAdd,
      }}
    >
      <StyledButton
        type="dashed"
        icon={<FontAwesomeIcon icon={faPlus} />}
        {...addButtonProps}
      >
        {addButtonText ?? "ADD"}
      </StyledButton>
    </StyledDropdown>
  )
}

const AddItemButton = props => {
  const { schema, onAppend, name, ...rest } = props

  const { items, metadata } = schema
  const { addButtonProps, addButtonText } = metadata || {}
  const handleAdd = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()
      const value = defaultItemMapping[items.type]
      onAppend(value)
    },
    [onAppend, items.type]
  )

  const resolvedItemsSchema = useResolvedSchema(
    items,
    defaultItemMapping[items.type]
  )

  const resolvedItemsSubschema = useResolvedSchema(
    Object.entries(resolvedItemsSchema?.properties || {})?.length === 1
      ? Object.values(resolvedItemsSchema?.properties || {})?.[0]
      : {},
    {}
  )

  //TODO: add dropdown to button if there array item has a single subschema that is a dropdown-type
  const itemsSchemaHasSoloDropdown =
    Object.entries(resolvedItemsSchema?.properties || {})?.length === 1 &&
    resolvedItemsSubschema?.enum

  if (itemsSchemaHasSoloDropdown) {
    return (
      <DropdownAddButton
        name={name}
        metadata={metadata}
        onAppend={onAppend}
        schema={resolvedItemsSubschema}
        schemaKey={Object.entries(resolvedItemsSchema.properties)[0][0]}
        {...rest}
      />
    )
  }
  return (
    <StyledButton
      type="dashed"
      onClick={handleAdd}
      icon={<FontAwesomeIcon icon={faPlus} />}
      {...addButtonProps}
      {...rest}
    >
      {addButtonText ?? "ADD"}
    </StyledButton>
  )
}

const SchemaArrayCore = memo(props => {
  const { name, path, onAppend, onChange, onRemove, schema, ...rest } = props
  const { metadata } = schema
  const { hideAdd, hideList } = metadata || {}

  return (
    <Row gutter={[0, 8]}>
      <Col span={24}>
        {!hideList ? (
          <FormList
            path={path}
            name={name}
            schema={schema}
            onChange={onChange}
            onRemove={onRemove}
            {...rest}
          />
        ) : null}
      </Col>
      {!hideAdd ? (
        <Col span={24}>
          <AddItemButton schema={schema} onAppend={onAppend} name={name} />
        </Col>
      ) : null}
    </Row>
  )
})

const SchemaArray = memo(props => {
  return (
    <FormFieldWrapper noStyle {...props}>
      <SchemaArrayCore />
    </FormFieldWrapper>
  )
})

SchemaArray.Core = SchemaArrayCore
export default SchemaArray
