import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { Editor } from "@tinymce/tinymce-react"

import { alphanumid } from "@dbai/tool-box"

import { useWidgetContext } from "../../hooks"
import { useLocalFormFieldControls } from "../hooks"
import { defaultInitOptions } from "../../lib/tinyMce"
import { selectAppVariables } from "../../selectors/app"
import { withFormFieldWrapper } from "../FormFieldWrapper"

const StyledEditorContainer = styled.div`
  .tox .tox-toolbar,
  .tox .tox-toolbar__overflow,
  .tox .tox-toolbar__primary {
    background: #eeeeee !important;
    border-bottom: 2px solid #d9d9d9 !important;
  }

  .tox-tinymce {
    border-color: #d9d9d9;
    border-radius: 6px;
  }

  .tox .tox-statusbar {
    background: #eeeeee !important;
  }
`

const handleInit = (evt, editor) => {
  editor.getBody().style.backgroundColor = "#eeeeee"
}

const RichTextCore = props => {
  const { appConfig } = useWidgetContext()
  const containerId = useRef(alphanumid())
  const { onChange, value: _value, schema } = props
  const { metadata = {} } = schema
  const { height = 300, ...opts } = metadata

  const [value, handleChange] = useLocalFormFieldControls(_value, onChange)
  const appVariables = useSelector(selectAppVariables)
  const appVariablesRef = useRef(appVariables)
  const valueRef = useRef(value)

  useEffect(() => {
    appVariablesRef.current = appVariables
  }, [appVariables])

  const init = {
    ...defaultInitOptions,
    height,
    fixed_toolbar_container: `#${containerId.current}`,
    toolbar_location: "top",
    toolbar:
      "undo redo | fontsizeinput | bold italic | align numlist bullist | link image | table media pageembed | lineheight  outdent indent | strikethrough forecolor backcolor formatpainter removeformat | charmap emoticons checklist | code fullscreen preview | save print export | pagebreak anchor codesample footnotes mergetags | addtemplate inserttemplate | addcomment showcomments | ltr rtl casechange | spellcheckdialog a11ycheck", // Note: if a toolbar item requires a plugin, the item will not present in the toolbar if the plugin is not also loaded.
    skin: "small",
    icons: "small",
    menubar: false,
    toolbar1: null,
    toolbar2: null,
    branding: false,
    elementpath: false,
    ...opts,
  }
  if (!appConfig?.tinyMCEApiKey) return null
  return (
    <StyledEditorContainer id={containerId.current}>
      <Editor
        init={init}
        onInit={handleInit}
        apiKey={appConfig.tinyMCEApiKey}
        initialValue={valueRef.current}
        onEditorChange={handleChange}
      />
    </StyledEditorContainer>
  )
}

const RichText = withFormFieldWrapper(RichTextCore)
RichText.Core = RichTextCore
export default RichText
