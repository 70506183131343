import React from "react"
import { Col, Button } from "antd"
import { Link } from "react-router-dom"
import { useQuery } from "@apollo/client"

import {
  DBTable,
  Content,
  PageSpinner,
  ErrorMessage,
  AuthBoundary,
  GET_CUSTOMERS,
  DELETE_CUSTOMER,
  ActionsFormatter,
  DateFormatter,
} from "@dbai/ui-staples"

import { stringSorter, numberSorter, dateSorter } from "@dbai/tool-box"

import PageHeader from "components/shared/PageHeader"
import { useDefaultPath } from "hooks"

const CustomActions = props => {
  const { row: customer } = props
  const [path, loading] = useDefaultPath(customer)

  if (loading) return null
  return (
    <ActionsFormatter
      value={customer.id}
      viewPath={`/${customer.normalizedName}/${path}`}
      deleteGql={DELETE_CUSTOMER}
      confirmDelete={true}
      refetchQuery={GET_CUSTOMERS}
    />
  )
}

const Customers = props => {
  const { error, loading, data } = useQuery(GET_CUSTOMERS)
  if (loading) return <PageSpinner />
  if (error) return <ErrorMessage error={error} />

  const routes = [{ breadcrumbName: "Customer Admin" }]

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: numberSorter("id"),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      defaultSortOrder: "ascend",
      sorter: stringSorter("name"),
      render: (text, row) => {
        return <Link to={`/${row.normalizedName}`}>{text}</Link>
      },
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: dateSorter("createdAt"),
      render: value => {
        return <DateFormatter value={value} />
      },
    },
  ]

  return (
    <>
      <PageHeader routes={routes}>
        <AuthBoundary subject="customers" action="CREATE">
          <Link to={`/admin/customers/new`}>
            <Button type="primary">+ NEW CUSTOMER</Button>
          </Link>
        </AuthBoundary>
      </PageHeader>

      <Content>
        <Col span={24}>
          <DBTable
            dataSource={data.customers}
            columns={columns}
            actions={CustomActions}
            rowLink={row => `/${row.normalizedName}`}
          />
        </Col>
      </Content>
    </>
  )
}

export default Customers
