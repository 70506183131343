const textSchema = {
  title: "Text",
  type: "object",
  default: {},
  metadata: {
    fieldProps: {
      style: {
        padding: "0 8px",
      },
    },
  },
  properties: {
    text: {
      type: "string",
      title: "Text",
      metadata: {
        colon: true,
        hidden: true,
        hideLabel: false,
      },
      minLength: 0,
    },
    backgroundColor: {
      title: "Background Color",
      type: ["string", "null"],
      nullable: true,
      metadata: {
        component: "ColorPicker",
      },
    },
    vPosition: {
      type: "string",
      default: "flex-start",
      title: "Vertical Position",
      enum: ["flex-start", "center", "flex-end"],
      metadata: {
        component: "Segmented",
        labels: ["Top", "Middle", "Bottom"],
      },
    },
    nodes: {
      title: "Text Nodes",
      type: "array",
      default: [],
      metadata: {
        hideLabel: false,
        itemType: "collapse",
        // component: "TextNodes",
        addButtonText: "ADD TEXT NODE",
        addButtonProps: {
          block: true,
        },
      },
      items: {
        type: "object",
        title: "Text Node",
        default: {},
        metadata: {
          order: ["nodeType", "text", "rules", "conditionalFormat"],
        },
        dependencies: {
          nodeType: {
            allOf: [
              {
                if: {
                  properties: {
                    nodeType: {
                      enum: ["appVariable"],
                    },
                  },
                },
                then: {
                  properties: {
                    text: {
                      type: "string",
                      metadata: {
                        hidden: true,
                      },
                    },
                    appVariable: {
                      type: "string",
                      title: "App Variable",
                      metadata: {
                        component: "AppVariableSelect",
                      },
                    },
                  },
                },
              },
              {
                if: {
                  properties: {
                    nodeType: {
                      enum: ["conditionalText"],
                    },
                  },
                },
                then: {
                  properties: {
                    text: {
                      type: "string",
                      title: "Text",
                      default: "Configurable Text Node",
                      metadata: {
                        component: "RichText",
                        height: 126,
                      },
                    },
                    rules: {
                      type: "array",
                      title: "Conditional Text",
                      default: [],
                      metadata: {
                        hideLabel: false,
                        addButtonText: "ADD CONDITIONAL TEXT",
                        addButtonProps: {
                          block: true,
                        },
                      },
                      items: {
                        type: "object",
                        title: "Rule",
                        default: {},
                        properties: {
                          title: {
                            title: "Rule Config",
                            type: "string",
                            default: "Rule Config",
                            metadata: {
                              hidden: true,
                            },
                          },
                          conditions: {
                            title: "When",
                            type: "array",
                            default: [{}],
                            metadata: {
                              hideLabel: false,
                              addButtonText: "ADD CONDITION",
                              addButtonProps: {
                                block: true,
                              },
                            },
                            items: {
                              default: {},
                              type: "object",
                              metadata: {
                                order: [
                                  "sourceType",
                                  "appVariable",
                                  "sourceDatasetId",
                                  "sourceValue",
                                  "sourceAggregate",
                                  "percentile",
                                  "operator",
                                  "valueType",
                                  "datasetId",
                                  "value",
                                ],
                              },
                              dependencies: {
                                sourceAggregate: {
                                  if: {
                                    properties: {
                                      sourceAggregate: {
                                        enum: ["PERCENTILE_CONT"],
                                      },
                                    },
                                  },
                                  then: {
                                    properties: {
                                      sourcePercentile: {
                                        type: "number",
                                        min: 0,
                                        max: 1,
                                        title: "Value",
                                        metadata: {
                                          hideLabel: true,
                                          showNumberInput: true,
                                          component: "SliderInput",
                                          formatter: {
                                            format: "percent",
                                          },
                                          fieldProps: {
                                            placeholder: "Percentile",
                                            popupMatchSelectWidth: false,
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                                aggregate: {
                                  if: {
                                    properties: {
                                      aggregate: {
                                        enum: ["PERCENTILE_CONT"],
                                      },
                                    },
                                  },
                                  then: {
                                    properties: {
                                      percentile: {
                                        type: "number",
                                        min: 0,
                                        max: 1,
                                        title: "Value",
                                        metadata: {
                                          hideLabel: true,
                                          showNumberInput: true,
                                          component: "SliderInput",
                                          formatter: {
                                            format: "percent",
                                          },
                                          fieldProps: {
                                            placeholder: "Percentile",
                                            popupMatchSelectWidth: false,
                                          },
                                        },
                                      },
                                    },
                                  },
                                },
                                datasetId: {
                                  properties: {
                                    value: {
                                      type: "string",
                                      title: "Value",
                                      metadata: {
                                        hideLabel: true,
                                        columnTypeName: "type",
                                        component: "ColumnSelect",
                                        datasetIdName: "datasetId",
                                        fieldProps: {
                                          placeholder: "Select Column",
                                        },
                                      },
                                    },
                                    aggregate: {
                                      type: ["string", "null"],
                                      title: "Aggregate",
                                      enum: [
                                        "avg",
                                        "sum",
                                        "min",
                                        "max",
                                        "count",
                                        "PERCENTILE_CONT",
                                      ],
                                      nullable: true,
                                      metadata: {
                                        validateFirst: true,
                                        labels: [
                                          "Average",
                                          "Sum",
                                          "Minimum",
                                          "Maximum",
                                          "Count",
                                          "Percentile",
                                        ],
                                        fieldProps: {
                                          placeholder: "(All)",
                                          popupMatchSelectWidth: false,
                                        },
                                      },
                                    },
                                  },
                                },
                                sourceDatasetId: {
                                  properties: {
                                    sourceAggregate: {
                                      type: ["string", "null"],
                                      title: "Aggregate",
                                      enum: [
                                        "avg",
                                        "sum",
                                        "min",
                                        "max",
                                        "count",
                                        "PERCENTILE_CONT",
                                      ],
                                      nullable: true,
                                      metadata: {
                                        validateFirst: true,
                                        labels: [
                                          "Average",
                                          "Sum",
                                          "Minimum",
                                          "Maximum",
                                          "Count",
                                          "Percentile",
                                        ],
                                        fieldProps: {
                                          placeholder: "(All)",
                                          popupMatchSelectWidth: false,
                                        },
                                      },
                                    },
                                    sourceValue: {
                                      type: "string",
                                      title: "Value",
                                      metadata: {
                                        hideLabel: true,
                                        component: "ColumnSelect",
                                        datasetIdName: "sourceDatasetId",
                                        columnTypeName: "sourceValueType",
                                        fieldProps: {
                                          placeholder: "Select Column",
                                        },
                                      },
                                    },
                                  },
                                },
                                operator: {
                                  properties: {
                                    valueType: {
                                      type: "string",
                                      title: "Compare value type",
                                      enum: [
                                        "staticValue",
                                        "datasetColumn",
                                        "appVariable",
                                      ],
                                      metadata: {
                                        hideLabel: true,
                                        labels: [
                                          "Value",
                                          "Other Column",
                                          "App Variable",
                                        ],
                                      },
                                    },
                                  },
                                },
                                sourceValueType: {
                                  allOf: [
                                    {
                                      if: {
                                        properties: {
                                          sourceValueType: {
                                            enum: ["datetime"],
                                          },
                                        },
                                      },
                                      then: {
                                        properties: {
                                          operator: {
                                            type: "string",
                                            title: "Operator",
                                            default: "=",
                                            enum: [
                                              "=",
                                              "!=",
                                              "<",
                                              ">",
                                              "<=",
                                              ">=",
                                            ],
                                            metadata: {
                                              hideLabel: true,
                                              fieldProps: {
                                                placeholder: "Select value",
                                                popupMatchSelectWidth: false,
                                              },
                                              labels: [
                                                "is equal to",
                                                "is not equal to",
                                                "is before",
                                                "is after",
                                                "is before or equal to",
                                                "is after or equal to",
                                              ],
                                            },
                                          },
                                        },
                                      },
                                    },
                                    {
                                      if: {
                                        properties: {
                                          sourceValueType: {
                                            enum: [
                                              "string",
                                              "categorical",
                                              "any",
                                            ],
                                          },
                                        },
                                      },
                                      then: {
                                        properties: {
                                          operator: {
                                            type: "string",
                                            title: "Operator",
                                            default: "=",
                                            enum: ["=", "!="],
                                            metadata: {
                                              hideLabel: true,
                                              fieldProps: {
                                                placeholder: "Select value",
                                                popupMatchSelectWidth: false,
                                              },
                                              labels: ["is in", "is not in"],
                                            },
                                          },
                                        },
                                      },
                                    },
                                    {
                                      if: {
                                        properties: {
                                          sourceValueType: {
                                            enum: [
                                              "numerical",
                                              "float",
                                              "int",
                                              "number",
                                            ],
                                          },
                                        },
                                      },
                                      then: {
                                        properties: {
                                          operator: {
                                            type: "string",
                                            title: "Operator",
                                            default: "=",
                                            enum: [
                                              "=",
                                              "!=",
                                              "<",
                                              ">",
                                              "<=",
                                              ">=",
                                            ],
                                            metadata: {
                                              hideLabel: true,
                                              fieldProps: {
                                                popupMatchSelectWidth: false,
                                              },
                                              labels: [
                                                "is equal to",
                                                "is not equal to",
                                                "is less than",
                                                "is greater than",
                                                "is less than or equal to",
                                                "is greater than or equal to",
                                              ],
                                            },
                                          },
                                        },
                                      },
                                    },
                                  ],
                                },
                                valueType: {
                                  allOf: [
                                    {
                                      if: {
                                        properties: {
                                          valueType: {
                                            enum: ["datasetColumn"],
                                          },
                                        },
                                      },
                                      then: {
                                        properties: {
                                          datasetId: {
                                            $ref: "#/$defs/datasetId",
                                          },
                                        },
                                      },
                                    },
                                    {
                                      if: {
                                        properties: {
                                          valueType: {
                                            enum: ["staticValue"],
                                          },
                                          sourceValueType: {
                                            enum: ["datetime"],
                                          },
                                        },
                                      },
                                      then: {
                                        properties: {
                                          value: {
                                            type: "string",
                                            title: "Value",
                                            metadata: {
                                              hideLabel: true,
                                              component: "DatePicker",
                                            },
                                          },
                                        },
                                      },
                                    },
                                    {
                                      if: {
                                        properties: {
                                          valueType: {
                                            enum: ["staticValue"],
                                          },
                                          sourceValueType: {
                                            enum: [
                                              "string",
                                              "categorical",
                                              "any",
                                            ],
                                          },
                                          operator: {
                                            enum: ["=", "!="],
                                          },
                                        },
                                      },
                                      then: {
                                        properties: {
                                          value: {
                                            type: "array",
                                            items: {
                                              type: "string",
                                            },
                                            metadata: {
                                              component: "LocalSelect",
                                              hideLabel: true,
                                              allowInput: true,
                                              isMulti: true,
                                            },
                                          },
                                        },
                                      },
                                    },
                                    {
                                      if: {
                                        properties: {
                                          valueType: {
                                            enum: ["staticValue"],
                                          },
                                          sourceValueType: {
                                            enum: [
                                              "string",
                                              "categorical",
                                              "any",
                                            ],
                                          },
                                          operator: {
                                            enum: ["<", ">", "<=", ">="],
                                          },
                                        },
                                      },
                                      then: {
                                        properties: {
                                          value: {
                                            type: "string",
                                            metadata: {
                                              hideLabel: true,
                                            },
                                          },
                                        },
                                      },
                                    },
                                    {
                                      if: {
                                        properties: {
                                          valueType: {
                                            enum: ["staticValue"],
                                          },
                                          sourceValueType: {
                                            enum: [
                                              "numerical",
                                              "float",
                                              "int",
                                              "number",
                                            ],
                                          },
                                        },
                                      },
                                      then: {
                                        properties: {
                                          value: {
                                            type: "number",
                                            metadata: {
                                              hideLabel: true,
                                              excludeWidgetFilters: true,
                                            },
                                          },
                                        },
                                      },
                                    },
                                    {
                                      if: {
                                        properties: {
                                          valueType: {
                                            enum: ["appVariable"],
                                          },
                                        },
                                      },
                                      then: {
                                        properties: {
                                          value: {
                                            type: "string",
                                            title: "App Variable",
                                            metadata: {
                                              hideLabel: true,
                                              component: "AppVariableSelect",
                                            },
                                          },
                                        },
                                      },
                                    },
                                  ],
                                },
                                sourceType: {
                                  allOf: [
                                    {
                                      if: {
                                        properties: {
                                          sourceType: {
                                            enum: ["appVariable"],
                                          },
                                        },
                                      },
                                      then: {
                                        properties: {
                                          sourceValue: {
                                            type: "string",
                                            title: "App Variable",
                                            metadata: {
                                              hideLabel: true,
                                              component: "AppVariableSelect",
                                              variableTypeName:
                                                "sourceValueType",
                                              metadata: {
                                                placeholder: "App Variable",
                                              },
                                            },
                                          },
                                        },
                                      },
                                    },
                                    {
                                      if: {
                                        properties: {
                                          sourceType: {
                                            enum: ["datasetColumn"],
                                          },
                                        },
                                      },
                                      then: {
                                        properties: {
                                          sourceDatasetId: {
                                            $ref: "#/$defs/datasetId",
                                          },
                                        },
                                      },
                                    },
                                  ],
                                },
                              },
                              properties: {
                                conjunction: {
                                  type: "string",
                                  enum: ["and", "or"],
                                  default: "and",
                                  metadata: {
                                    labels: ["AND", "OR"],
                                    hideLabel: true,
                                    hidden: {
                                      itemIndex: 0,
                                    },
                                  },
                                },
                                sourceType: {
                                  type: "string",
                                  enum: ["datasetColumn", "appVariable"],
                                  metadata: {
                                    hideLabel: true,
                                    labels: ["Dataset Column", "App Variable"],
                                    fieldProps: {
                                      placeholder: "Source type",
                                    },
                                  },
                                },
                                sourceValueType: {
                                  type: "string",
                                  metadata: {
                                    hidden: true,
                                  },
                                },
                                type: {
                                  type: "string",
                                  metadata: {
                                    hidden: true,
                                  },
                                },
                              },
                            },
                          },
                          then: {
                            type: "object",
                            properties: {
                              text: {
                                type: "string",
                                title: "Then, show text as",
                                default: "Conditional Text",
                                description:
                                  "This text is shown when the conditions are evaluated to true",
                                metadata: {
                                  component: "RichText",
                                  height: 126,
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            ],
          },
        },
        properties: {
          nodeType: {
            type: "string",
            enum: ["appVariable", "conditionalText"],
            metadata: {
              labels: ["App Variable", "Text"],
              fieldProps: {
                disabled: true,
                placeholder: "Select a node type",
              },
            },
          },
          nodeId: {
            title: "Node",
            type: "string",
            metadata: {
              hidden: true,
              defaultValueSource: "uid",
            },
          },
          conditionalFormat: {
            type: "array",
            title: "Conditional Formatting",
            metadata: {
              hideLabel: false,
              addButtonText: "ADD CONDITIONAL FORMATTING RULE",
              addButtonProps: {
                block: true,
              },
            },
            items: {
              type: "object",
              title: "Scenario",
              properties: {
                title: {
                  type: "string",
                  default: "Condition Scenario",
                  metadata: {
                    hidden: true,
                  },
                },
                conditions: {
                  type: "array",
                  title: "When",
                  default: [{}],
                  metadata: {
                    hideLabel: false,
                    addButtonText: "ADD CONDITION",
                    addButtonProps: {
                      block: true,
                    },
                  },
                  items: {
                    default: {},
                    type: "object",
                    metadata: {
                      order: [
                        "sourceType",
                        "appVariable",
                        "sourceDatasetId",
                        "sourceValue",
                        "sourceAggregate",
                        "percentile",
                        "operator",
                        "valueType",
                        "datasetId",
                        "value",
                      ],
                    },
                    dependencies: {
                      sourceAggregate: {
                        if: {
                          properties: {
                            sourceAggregate: {
                              enum: ["PERCENTILE_CONT"],
                            },
                          },
                        },
                        then: {
                          properties: {
                            sourcePercentile: {
                              type: "number",
                              min: 0,
                              max: 1,
                              title: "Value",
                              metadata: {
                                hideLabel: true,
                                showNumberInput: true,
                                component: "SliderInput",
                                formatter: {
                                  format: "percent",
                                },
                                fieldProps: {
                                  placeholder: "Percentile",
                                  popupMatchSelectWidth: false,
                                },
                              },
                            },
                          },
                        },
                      },
                      aggregate: {
                        if: {
                          properties: {
                            aggregate: {
                              enum: ["PERCENTILE_CONT"],
                            },
                          },
                        },
                        then: {
                          properties: {
                            percentile: {
                              type: "number",
                              min: 0,
                              max: 1,
                              title: "Value",
                              metadata: {
                                hideLabel: true,
                                showNumberInput: true,
                                component: "SliderInput",
                                formatter: {
                                  format: "percent",
                                },
                                fieldProps: {
                                  placeholder: "Percentile",
                                  popupMatchSelectWidth: false,
                                },
                              },
                            },
                          },
                        },
                      },
                      datasetId: {
                        properties: {
                          value: {
                            type: "string",
                            title: "Value",
                            metadata: {
                              hideLabel: true,
                              columnTypeName: "type",
                              component: "ColumnSelect",
                              datasetIdName: "datasetId",
                              fieldProps: {
                                placeholder: "Select Column",
                              },
                            },
                          },
                          aggregate: {
                            type: ["string", "null"],
                            title: "Aggregate",
                            enum: [
                              "avg",
                              "sum",
                              "min",
                              "max",
                              "count",
                              "PERCENTILE_CONT",
                            ],
                            nullable: true,
                            metadata: {
                              validateFirst: true,
                              labels: [
                                "Average",
                                "Sum",
                                "Minimum",
                                "Maximum",
                                "Count",
                                "Percentile",
                              ],
                              fieldProps: {
                                placeholder: "(All)",
                                popupMatchSelectWidth: false,
                              },
                            },
                          },
                        },
                      },
                      sourceDatasetId: {
                        properties: {
                          sourceAggregate: {
                            type: ["string", "null"],
                            title: "Aggregate",
                            enum: [
                              "avg",
                              "sum",
                              "min",
                              "max",
                              "count",
                              "PERCENTILE_CONT",
                            ],
                            nullable: true,
                            metadata: {
                              validateFirst: true,
                              labels: [
                                "Average",
                                "Sum",
                                "Minimum",
                                "Maximum",
                                "Count",
                                "Percentile",
                              ],
                              fieldProps: {
                                placeholder: "(All)",
                                popupMatchSelectWidth: false,
                              },
                            },
                          },
                          sourceValue: {
                            type: "string",
                            title: "Value",
                            metadata: {
                              hideLabel: true,
                              component: "ColumnSelect",
                              datasetIdName: "sourceDatasetId",
                              columnTypeName: "sourceValueType",
                              fieldProps: {
                                placeholder: "Select Column",
                              },
                            },
                          },
                        },
                      },
                      operator: {
                        properties: {
                          valueType: {
                            type: "string",
                            title: "Compare value type",
                            enum: [
                              "staticValue",
                              "datasetColumn",
                              "appVariable",
                            ],
                            metadata: {
                              hideLabel: true,
                              labels: ["Value", "Other Column", "App Variable"],
                            },
                          },
                        },
                      },
                      sourceValueType: {
                        allOf: [
                          {
                            if: {
                              properties: {
                                sourceValueType: {
                                  enum: ["datetime"],
                                },
                              },
                            },
                            then: {
                              properties: {
                                operator: {
                                  type: "string",
                                  title: "Operator",
                                  default: "=",
                                  enum: ["=", "!=", "<", ">", "<=", ">="],
                                  metadata: {
                                    hideLabel: true,
                                    fieldProps: {
                                      placeholder: "Select value",
                                      popupMatchSelectWidth: false,
                                    },
                                    labels: [
                                      "is equal to",
                                      "is not equal to",
                                      "is before",
                                      "is after",
                                      "is before or equal to",
                                      "is after or equal to",
                                    ],
                                  },
                                },
                              },
                            },
                          },
                          {
                            if: {
                              properties: {
                                sourceValueType: {
                                  enum: ["string", "categorical", "any"],
                                },
                              },
                            },
                            then: {
                              properties: {
                                operator: {
                                  type: "string",
                                  title: "Operator",
                                  default: "=",
                                  enum: ["=", "!="],
                                  metadata: {
                                    hideLabel: true,
                                    fieldProps: {
                                      placeholder: "Select value",
                                      popupMatchSelectWidth: false,
                                    },
                                    labels: ["is in", "is not in"],
                                  },
                                },
                              },
                            },
                          },
                          {
                            if: {
                              properties: {
                                sourceValueType: {
                                  enum: ["numerical", "float", "int", "number"],
                                },
                              },
                            },
                            then: {
                              properties: {
                                operator: {
                                  type: "string",
                                  title: "Operator",
                                  default: "=",
                                  enum: ["=", "!=", "<", ">", "<=", ">="],
                                  metadata: {
                                    hideLabel: true,
                                    fieldProps: {
                                      popupMatchSelectWidth: false,
                                    },
                                    labels: [
                                      "is equal to",
                                      "is not equal to",
                                      "is less than",
                                      "is greater than",
                                      "is less than or equal to",
                                      "is greater than or equal to",
                                    ],
                                  },
                                },
                              },
                            },
                          },
                        ],
                      },
                      valueType: {
                        allOf: [
                          {
                            if: {
                              properties: {
                                valueType: {
                                  enum: ["datasetColumn"],
                                },
                              },
                            },
                            then: {
                              properties: {
                                datasetId: {
                                  $ref: "#/$defs/datasetId",
                                },
                              },
                            },
                          },
                          {
                            if: {
                              properties: {
                                valueType: {
                                  enum: ["staticValue"],
                                },
                                sourceValueType: {
                                  enum: ["datetime"],
                                },
                              },
                            },
                            then: {
                              properties: {
                                value: {
                                  type: "string",
                                  title: "Value",
                                  metadata: {
                                    hideLabel: true,
                                    component: "DatePicker",
                                  },
                                },
                              },
                            },
                          },
                          {
                            if: {
                              properties: {
                                valueType: {
                                  enum: ["staticValue"],
                                },
                                sourceValueType: {
                                  enum: ["string", "categorical", "any"],
                                },
                                operator: {
                                  enum: ["=", "!="],
                                },
                              },
                            },
                            then: {
                              properties: {
                                value: {
                                  type: "array",
                                  items: {
                                    type: "string",
                                  },
                                  metadata: {
                                    component: "LocalSelect",
                                    isMulti: true,
                                    allowInput: true,
                                    hideLabel: true,
                                    excludeWidgetFilters: true,
                                  },
                                },
                              },
                            },
                          },
                          {
                            if: {
                              properties: {
                                valueType: {
                                  enum: ["staticValue"],
                                },
                                sourceValueType: {
                                  enum: ["numerical", "float", "int", "number"],
                                },
                              },
                            },
                            then: {
                              properties: {
                                value: {
                                  type: "number",
                                  metadata: {
                                    hideLabel: true,
                                    excludeWidgetFilters: true,
                                  },
                                },
                              },
                            },
                          },
                          {
                            if: {
                              properties: {
                                valueType: {
                                  enum: ["appVariable"],
                                },
                              },
                            },
                            then: {
                              properties: {
                                value: {
                                  type: "string",
                                  title: "App Variable",
                                  metadata: {
                                    hideLabel: true,
                                    component: "AppVariableSelect",
                                  },
                                },
                              },
                            },
                          },
                        ],
                      },
                      sourceType: {
                        allOf: [
                          {
                            if: {
                              properties: {
                                sourceType: {
                                  enum: ["appVariable"],
                                },
                              },
                            },
                            then: {
                              properties: {
                                sourceValue: {
                                  type: "string",
                                  title: "App Variable",
                                  metadata: {
                                    hideLabel: true,
                                    component: "AppVariableSelect",
                                    variableTypeName: "sourceValueType",
                                    metadata: {
                                      placeholder: "App Variable",
                                    },
                                  },
                                },
                              },
                            },
                          },
                          {
                            if: {
                              properties: {
                                sourceType: {
                                  enum: ["datasetColumn"],
                                },
                              },
                            },
                            then: {
                              properties: {
                                sourceDatasetId: {
                                  $ref: "#/$defs/datasetId",
                                },
                              },
                            },
                          },
                        ],
                      },
                    },
                    properties: {
                      conjunction: {
                        type: "string",
                        enum: ["and", "or"],
                        default: "and",
                        metadata: {
                          labels: ["AND", "OR"],
                          hideLabel: true,
                          hidden: {
                            itemIndex: 0,
                          },
                        },
                      },
                      sourceType: {
                        type: "string",
                        enum: ["datasetColumn", "appVariable"],
                        metadata: {
                          hideLabel: true,
                          labels: ["Dataset Column", "App Variable"],
                          fieldProps: {
                            placeholder: "Source type",
                          },
                        },
                      },
                      sourceValueType: {
                        type: "string",
                        metadata: {
                          hidden: true,
                        },
                      },
                      type: {
                        type: "string",
                        metadata: {
                          hidden: true,
                        },
                      },
                    },
                  },
                },
                effects: {
                  type: "array",
                  title: "Then, apply",
                  default: [{}],
                  metadata: {
                    hideLabel: false,
                    addButtonText: "ADD EFFECT",
                    addButtonProps: {
                      block: true,
                    },
                  },
                  items: {
                    type: "object",
                    default: {},
                    metadata: {
                      layout: {
                        gutter: [8],
                      },
                      sideEffects: [
                        {
                          resetValue: null,
                          type: "resetField",
                          targetName: "value",
                          sourceName: "attribute",
                        },
                      ],
                    },
                    dependencies: {
                      attribute: {
                        allOf: [
                          {
                            if: {
                              properties: {
                                attribute: {
                                  enum: ["--conditional-display"],
                                },
                              },
                            },
                            then: {
                              properties: {
                                value: {
                                  type: "string",
                                  title: "Value",
                                  default: "none",
                                  metadata: {
                                    hidden: true,
                                  },
                                },
                              },
                            },
                          },
                          {
                            if: {
                              properties: {
                                attribute: {
                                  enum: [
                                    "--conditional-color",
                                    "--conditional-background-color",
                                  ],
                                },
                              },
                            },
                            then: {
                              properties: {
                                value: {
                                  type: "string",
                                  title: "Value",
                                  metadata: {
                                    layout: {
                                      flex: 1,
                                    },
                                    hideLabel: true,
                                    component: "ColorPicker",
                                  },
                                },
                              },
                            },
                          },
                        ],
                      },
                    },
                    properties: {
                      attribute: {
                        type: "string",
                        title: "Attribute",
                        enum: [
                          "--conditional-display",
                          "--conditional-color",
                          "--conditional-background-color",
                        ],
                        metadata: {
                          hideLabel: true,
                          labels: [
                            "Hide Text",
                            "Text Color",
                            "Background Color",
                          ],
                          fieldProps: {
                            popupMatchSelectWidth: false,
                            placeholder: "Select Attribute",
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}

export default textSchema
