import React from "react"

import BaseInput from "../BaseInput"
import DatePicker from "../DatePicker"
import SwitchInput from "../SwitchInput"
import NumberInput from "../NumberInput"
import FormFieldWrapper from "../../FormFieldWrapper"

import ColumnValueSelect from "../ColumnValueSelect"

const getIsMultiSelect = schema => {
  const { isMulti } = schema.metadata || {}
  return isMulti || schema.type === "array"
}

const ColumnValueInputCore = React.memo(props => {
  const { schema } = props
  const { metadata } = schema
  const { columnType, valuePresets = "none" } = metadata
  const isMultiSelect = getIsMultiSelect(schema)

  // show selection field if multi select is enabled, or if value presets are enabled
  if (isMultiSelect || valuePresets !== "none") {
    return <ColumnValueSelect {...props} />
  }

  switch (columnType) {
    case "numerical":
    case "number":
    case "int":
    case "float":
      return <NumberInput.Core {...props} />
    case "boolean":
      return <SwitchInput.Core {...props} />
    case "datetime":
      return <DatePicker.Core {...props} />
    case "string":
    case "categorical":
    default:
      return <BaseInput.Core {...props} />
  }
})

const ColumnValueInput = props => {
  const { name, value } = props
  return (
    <FormFieldWrapper {...props}>
      <ColumnValueInputCore parentValue={value} parentName={name} />
    </FormFieldWrapper>
  )
}

ColumnValueInput.Core = ColumnValueInputCore
export default ColumnValueInput
