import { useCustomerRoles, checkForRole } from "@dbai/ui-staples"

const PATH_ORDER = [
  { path: "datalake", subject: "nodes", action: "LIST" },
  { path: "workflows", subject: "workflows", action: "LIST" },
  { path: "models", subject: "models", action: "LIST" },
  { path: "datasets", subject: "datasets", action: "LIST" },
  { path: "analyses", subject: "analyses", action: "LIST" },
  { path: "apps", subject: "apps", action: "LIST" },
  { path: "settings", subject: "users", action: "LIST" },
]

const deny = "DENY"

export const useDefaultPath = customer => {
  const [customerRoles, loading] = useCustomerRoles(customer)
  for (let nav of PATH_ORDER) {
    const details = {
      roles: customerRoles,
      subject: nav.subject,
      action: nav.action,
    }
    const hasRole =
      !checkForRole({ ...details, effect: deny }) && checkForRole(details)
    if (hasRole) return [nav.path, loading]
  }
  return [PATH_ORDER[0].path, loading]
}
