import React from "react"
import styled from "styled-components"
import { Flex } from "antd"
import { PropTypes } from "prop-types"

const StyledFlex = styled(Flex)`
  width: 100%;
`

/** Layout Wrapper is meant to wrap over form fields and provide a consistent layout
 * based on the schema.
 */
const LayoutWrapper = props => {
  const { children, container, className, containerStyle, schema } = props
  const style = containerStyle || schema.metadata?.fieldProps?.style || {}

  if (container) {
    return (
      <StyledFlex className={className} style={style} vertical>
        {children}
      </StyledFlex>
    )
  }
  if (style) {
    return (
      <div className={className} style={style}>
        {children}
      </div>
    )
  }
  return children
}

LayoutWrapper.propTypes = {
  schema: PropTypes.object,
  layout: PropTypes.string,
}
export default LayoutWrapper
