import { gql } from "@apollo/client"

export const GET_WORKFLOWS = gql`
  query Workflows($cname: String!) {
    customer(name: $cname) {
      id
      workflows {
        id
        name
        tags
        published
        createdAt
        updatedAt
        resources {
          requests {
            memory
            cpu
          }
          limits {
            memory
            cpu
          }
        }
      }
    }
  }
`

export const DELETE_WORKFLOW = gql`
  mutation DeleteWorkflow($id: Int!, $customerId: Int!) {
    deleteWorkflow(id: $id, customerId: $customerId) {
      id
    }
  }
`

export const UPDATE_WORKFLOW = gql`
  mutation UpdateWorkflow(
    $customerId: Int!
    $id: Int!
    $input: WorkflowInput!
  ) {
    updateWorkflow(customerId: $customerId, id: $id, input: $input) {
      id
      createdAt
      updatedAt
    }
  }
`

export const RUN_WORKFLOW = gql`
  mutation RunWorkflow($cid: Int!, $id: Int!) {
    runWorkflow(customerId: $cid, id: $id) {
      id
      name
    }
  }
`

const SPEC_FRAGMENT = gql`
  fragment SpecFragment on WorkflowSpec {
    nodes {
      __typename
      id
      type
      icon
      collapsed
      position {
        x
        y
      }
      data {
        label
        color
      }
      ... on WorkflowScriptNode {
        arguments {
          name
          type
        }
        cells {
          uuid
          source
          executionCount
          metadata
          cellType
          outputs
        }
        artifacts {
          id
          title
          path
          type
        }
        position {
          x
          y
        }
        data {
          label
          color
        }
        metadata
      }
      ... on WorkflowComponentNode {
        arguments {
          name
          type
        }
        cells {
          uuid
          source
          executionCount
          metadata
          cellType
          outputs
        }
        artifacts {
          id
          title
          path
          type
        }
        position {
          x
          y
        }
        data {
          label
          color
        }
        metadata
      }
      ... on WorkflowEmptyNode {
        position {
          x
          y
        }
        data {
          label
          color
        }
      }
      ... on WorkflowSourceNode {
        datasetId
        artifacts {
          id
          title
        }
      }
      ... on WorkflowDownloadNode {
        files {
          uploadId
          filename
          artifactId
        }
        artifacts {
          id
          title
          path
        }
      }
      ... on WorkflowSinkNode {
        datasetId
      }
    }
    edges {
      id
      type
      source
      target
      parameters {
        artifactId
        argument
        filename
      }
    }
  }
`

const WORKFLOW_FRAGMENT = gql`
  ${SPEC_FRAGMENT}
  fragment WorkflowFragment on Workflow {
    id
    name
    tags
    resources {
      requests {
        memory
        cpu
      }
      limits {
        memory
        cpu
      }
    }
    spec {
      ...SpecFragment
    }
  }
`

export const GET_WORKFLOW = gql`
  ${WORKFLOW_FRAGMENT}
  query GetWorkflow($id: Int!, $cname: String!) {
    customer(name: $cname) {
      id
      name
      normalizedName
      workflow(id: $id) {
        ...WorkflowFragment
      }
    }
  }
`

export const GET_WORKFLOW_METADATA = gql`
  query WorkflowMetadata($id: Int!, $cname: String!) {
    customer(name: $cname) {
      id
      workflow(id: $id) {
        id
        name
        tags
        createdAt
        updatedAt
        resources {
          requests {
            memory
            cpu
          }
          limits {
            memory
            cpu
          }
        }
      }
    }
  }
`

export const CLONE_WORKFLOW = gql`
  ${SPEC_FRAGMENT}
  mutation CloneWorkflow($customerId: Int!, $id: Int!, $name: String!) {
    cloneWorkflow(customerId: $customerId, id: $id, name: $name) {
      id
      name
      spec {
        ...SpecFragment
      }
    }
  }
`

export const CREATE_WORKFLOW = gql`
  mutation CreateWorkflow($customerId: Int!, $input: WorkflowInput!) {
    createWorkflow(customerId: $customerId, input: $input) {
      id
      name
      tags
      createdAt
      updatedAt
      resources {
        requests {
          memory
          cpu
        }
        limits {
          memory
          cpu
        }
      }
    }
  }
`

export const GET_UPLOADS = gql`
  query GetUploads($cname: String!) {
    customer(name: $cname) {
      id
      uploads {
        id
        originalFilename
      }
    }
  }
`

export const GET_WORKFLOW_RUNS = gql`
  query GetWorkflowRuns($id: Int!, $cname: String!) {
    customer(name: $cname) {
      workflow(id: $id) {
        workflowRuns {
          id
          workflowId
          argoIdentifier
          createdAt
          updatedAt
          argoWorkflow {
            metadata {
              uid
              name
              creationTimestamp
            }
            status {
              startedAt
              finishedAt
              phase
              progress
            }
          }
        }
      }
    }
  }
`

export const GET_ARGO_WORKFLOW_DETAILS = gql`
  query GetArgoWorkflowDetails($customerId: Int!, $uid: String!) {
    argoWorkflow(customerId: $customerId, uid: $uid) {
      spec {
        dag {
          tasks {
            dependencies
            template
          }
        }
      }
      status {
        phase
        nodes {
          id
          phase
          message
          templateName
        }
      }
    }
  }
`

export const GET_WORKFLOW_RUN_DETAILS = gql`
  query WorkflowRunDetails($cname: String!, $id: Int!) {
    workflowRun(cname: $cname, id: $id) {
      id
      workflowId
      argoIdentifier
      results {
        logs {
          date
          event
          nodeName
        }
      }
    }
  }
`

export const GET_WORKFLOW_SNAPSHOTS = gql`
  query workflowSnapshots($customerId: Int!, $id: Int!) {
    workflow(customerId: $customerId, id: $id) {
      id
      snapshots {
        id
        description
        createdAt
      }
    }
  }
`

export const RESTORE_WORKFLOW_FROM_SNAPSHOT = gql`
  ${WORKFLOW_FRAGMENT}
  mutation RestoreWorkflowFromSnapshot(
    $customerId: Int!
    $id: Int!
    $snapshotId: Int!
  ) {
    restoreWorkflow(customerId: $customerId, id: $id, snapshotId: $snapshotId) {
      ...WorkflowFragment
    }
  }
`

export const CLONE_WORKFLOW_FROM_SNAPSHOT = gql`
  mutation CloneFromSnapshot(
    $customerId: Int!
    $snapshotId: Int!
    $name: String!
  ) {
    cloneWorkflowFromSnapshot(
      customerId: $customerId
      snapshotId: $snapshotId
      name: $name
    ) {
      id
      name
    }
  }
`

export const WORKFLOW_RESOURCE_USAGE = gql`
  query WorkflowResourceUsage(
    $id: Int!
    $customerId: Int!
    $startTime: DateTime!
    $endTime: DateTime!
  ) {
    workflowNotebookResourceUsage(
      id: $id
      customerId: $customerId
      startTime: $startTime
      endTime: $endTime
    ) {
      cpu {
        timestamp
        value
      }
      memory {
        timestamp
        value
      }
    }
  }
`

export const GET_WORKFLOW_NOTEBOOK_VERSION = gql`
  query workflowNotebookVersion {
    workflowNotebookVersion {
      version
    }
  }
`

export const GET_WORKFLOW_COMMENTS = gql`
  query WorkflowComments($cname: String!, $id: Int!) {
    customer(name: $cname) {
      workflow(id: $id) {
        comments {
          content
          id
          userId
          userName
          createdAt
        }
      }
    }
  }
`

export const CREATE_WORKFLOW_COMMENT = gql`
  mutation CreateWorkflowComment(
    $cname: String!
    $id: Int!
    $content: String!
  ) {
    createComment(
      cname: $cname
      input: { resourceId: $id, resourceType: workflows, content: $content }
    ) {
      id
      userId
      userName
      content
      createdAt
    }
  }
`
export const GET_PUBLISHED_WORKFLOWS = gql`
  ${SPEC_FRAGMENT}
  query GetPublishedWorkflows {
    workflowPublications {
      id
      workflow {
        id
        name
        spec {
          ...SpecFragment
        }
      }
    }
  }
`
export const PUBLISH_WORKFLOW = gql`
  mutation PublishWorkflow($workflowId: Int!, $customerId: Int!) {
    publishWorkflow(workflowId: $workflowId, customerId: $customerId) {
      id
    }
  }
`
export const DELETE_WORKFLOW_PUBLICATION = gql`
  mutation DeleteWorkflow($workflowId: Int!, $customerId: Int!) {
    deleteWorkflowPublication(workflowId: $workflowId, customerId: $customerId)
  }
`
export const CLONE_WORKFLOW_PUBLICATION = gql`
  mutation CloneWorkflowPublication(
    $workflowPublicationId: Int!
    $name: String!
    $customerId: Int!
  ) {
    cloneWorkflowFromPublication(
      id: $workflowPublicationId
      name: $name
      customerId: $customerId
    ) {
      id
      name
    }
  }
`
