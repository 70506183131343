import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useSelector, useDispatch } from "react-redux"
import { useQuery } from "@apollo/client"

import { Card, Spinner, Select, ErrorMessage } from "@dbai/ui-staples"
import { selectWorkflows } from "selectors"
import { actions as scheduleActions } from "reducers/scheduleReducer"
import { actions as packagesActions } from "reducers/packagesReducer"
import { actions as workflowsActions } from "reducers/workflowsReducer"
import PackagesTable from "components/pages/Workflows/shared/PackagesTable"
import { GET_CUSTOMER_PACKAGES } from "queries"

const PackagesCard = styled(Card)`
  margin-bottom: 10px;
`

const workflowToOpt = workflow => ({
  label: workflow.name,
  value: workflow,
})

const SearchPackages = ({ customer }) => {
  const dispatch = useDispatch()
  const { loading, error, data } = useSelector(selectWorkflows)
  const packages = useSelector(state => state.packages.packages)

  const {
    data: packagesData,
    loading: packagesLoading,
    error: packagesError,
  } = useQuery(GET_CUSTOMER_PACKAGES, {
    variables: { cname: customer.normalizedName },
  })

  const [selectedWorkflow, setSelectedWorkflow] = useState(null)

  useEffect(() => {
    return () => dispatch(packagesActions.reset())
  }, [dispatch])

  useEffect(() => {
    dispatch(workflowsActions.loadWorkflows({ cname: customer.normalizedName }))
    return () => dispatch(workflowsActions.reset())
  }, [dispatch, customer.normalizedName])

  useEffect(() => {
    if (!selectedWorkflow) return

    dispatch(
      packagesActions.loadPackages({
        cname: customer.normalizedName,
        workflowId: selectedWorkflow.id,
      })
    )

    return () => dispatch(packagesActions.reset())
  }, [dispatch, selectedWorkflow, customer.normalizedName])

  if (loading || packagesLoading) return <Spinner />
  if (error) return <ErrorMessage error={error} />
  if (packagesError) return <ErrorMessage error={packagesError} />

  const workflowsWithPackages =
    data?.filter(workflow =>
      packagesData?.customer?.packages?.some(
        pkg => pkg.workflow.id === workflow.id
      )
    ) || []

  const workflowOpts = workflowsWithPackages.map(workflowToOpt)

  const handleWorkflowSelect = workflow => {
    setSelectedWorkflow(workflow)
  }

  const handlePackageSelect = pkg => {
    dispatch(scheduleActions.set({ name: "packageId", value: pkg.id }))
  }

  return (
    <PackagesCard nopad>
      <Card.Header>
        <Select
          placeholder="Select a Workflow..."
          options={workflowOpts}
          onChange={handleWorkflowSelect}
          value={selectedWorkflow}
          reset
        />
      </Card.Header>
      <PackagesTable packages={packages} onSelect={handlePackageSelect} />
    </PackagesCard>
  )
}

export default SearchPackages
