import React, { memo } from "react"
import { Row, Col } from "antd"

import ListItem from "./ListItem"

const DefaultListItemWrapper = ({ children }) => children
const DefaultListItems = props => {
  const {
    path,
    name,
    value,
    onRemove,
    schema = {},
    nodeClassName,
    handleClassName,
    layout = { gutter: [] },
    ListItemWrapper = DefaultListItemWrapper,
  } = props
  const { metadata = {} } = schema
  const { removable = true } = metadata
  return (
    <Row gutter={[0, 16]}>
      {value?.map((_, idx) => {
        return (
          <Col span={24} className={nodeClassName} key={`${path}.[${idx}]`}>
            <ListItemWrapper index={idx}>
              <ListItem
                idx={idx}
                draggable
                path={path}
                schema={schema}
                layout={layout}
                onRemove={onRemove}
                removable={removable}
                key={`${path}.[${idx}]`}
                name={`${name}[${idx}]`}
                nodeClassName={nodeClassName}
                handleClassName={handleClassName}
                pathWithIndex={`${path}.[${idx}]`}
              />
            </ListItemWrapper>
          </Col>
        )
      })}
    </Row>
  )
}

export default memo(DefaultListItems)
