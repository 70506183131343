import React from "react"
import { Tag } from "antd"

const StatusIndicator = props => {
  const { status } = props
  if (status === "validating") {
    return <Tag color="processing">Checking Schema...</Tag>
  }
  if (status === "error") {
    return <Tag color="error">Invalid Schema</Tag>
  }
  return <Tag color="success">Valid Schema!</Tag>
}

export default StatusIndicator
