import {
  enumOptionsSchema,
  scalarFieldOptions,
} from "../../lib/fieldOptionsSchemas"

export default {
  default: {},
  type: "object",
  required: ["enumOptions"],
  metadata: {
    layout: { gutter: [16] },
    sections: {
      ...scalarFieldOptions.metadata.sections,
      dropdownOptions: {
        title: "Dropdown Options",
        properties: [
          "enumOptions",
          "metadata/optionsSource",
          "metadata/datasetId",
          "metadata/column",
          "metadata/isMultiSelect",
          "metadata/fieldProps/popupMatchSelectWidth",
        ],
      },
    },
  },
  dependencies: {
    "metadata/optionsSource": {
      if: {
        properties: {
          "metadata/optionsSource": {
            enum: ["userDefined"],
          },
        },
      },
      then: {
        dependencies: {
          "metadata/isMultiSelect": {
            if: {
              properties: {
                "metadata/isMultiSelect": {
                  enum: [true],
                },
              },
            },
            then: {
              properties: {
                defaultValue: {
                  type: "array",
                  title: "Default Value",
                  items: { type: "string" },
                  metadata: {
                    valuePath: "value",
                    labelPath: "label",
                    relativeListPath: true,
                    listPath: "enumOptions",
                    component: "LocalSelect",
                    fieldProps: {
                      popupMatchSelectWidth: false,
                    },
                  },
                },
              },
            },
          },
        },
        properties: {
          enumOptions: enumOptionsSchema,
          defaultValue: {
            type: "string",
            title: "Default Value",
            metadata: {
              valuePath: "value",
              labelPath: "label",
              relativeListPath: true,
              listPath: "enumOptions",
              component: "LocalSelect",
              fieldProps: {
                popupMatchSelectWidth: false,
              },
            },
          },
        },
      },
      else: {
        dependencies: {
          "metadata/isMultiSelect": {
            if: {
              properties: {
                "metadata/isMultiSelect": {
                  enum: [true],
                },
              },
            },
            then: {
              properties: {
                defaultValue: {
                  type: "array",
                  title: "Default Value",
                  items: { type: "string" },
                  metadata: {
                    component: "ColumnValueInput",
                    valuePresets: true,
                    presetColumnName: "metadata/column",
                    presetDatasetIdName: "metadata/datasetId",
                    fieldProps: {
                      popupMatchSelectWidth: false,
                    },
                  },
                },
              },
            },
          },
          "metadata/datasetId": {
            properties: {
              "metadata/column": {
                title: "Column",
                type: "string",
                metadata: {
                  validateFirst: true,
                  saveColumnType: false,
                  component: "ColumnSelect",
                  fieldProps: {
                    popupMatchSelectWidth: false,
                  },
                },
              },
            },
          },
          "metadata/column": {
            properties: {
              defaultValue: {
                type: "string",
                title: "Default Value",
                metadata: {
                  component: "ColumnValueInput",
                  valuePresets: true,
                  presetColumnName: "metadata/column",
                  presetDatasetIdName: "metadata/datasetId",
                  fieldProps: {
                    popupMatchSelectWidth: false,
                  },
                },
              },
            },
          },
        },
        properties: {
          "metadata/datasetId": {
            type: "number",
            title: "Dataset",
            metadata: {
              component: "Dataset",
            },
          },
        },
      },
    },
  },
  properties: {
    ...scalarFieldOptions.properties,
    title: {
      type: "string",
      title: "Label",
      default: "Dropdown Form Field",
      metadata: {
        fieldProps: {
          placeholder: "Enter a label",
        },
      },
    },
    nullable: {
      type: "boolean",
      title: "Clearable",
      description: "Allow field to be removed from form state",
    },
    "metadata/optionsSource": {
      enum: ["userDefined", "datasetColumn"],
      default: "userDefined",
      title: "Options Source",
      description:
        "Select an options source to populate the dropdown values. If you select 'User Defined', you can manually enter the options. If you select 'Dataset Column', you can select a dataset and column to populate the options.",
      metadata: {
        labels: ["User Defined", "Dataset Column"],
        fieldProps: {
          placeholder: "Select a preset source",
        },
      },
    },
    "metadata/isMultiSelect": {
      type: "boolean",
      title: "Multi-select",
    },
    "metadata/fieldProps/placeholder": {
      type: "string",
      title: "Placeholder",
    },
    "metadata/fieldProps/popupMatchSelectWidth": {
      default: false,
      type: "boolean",
      title: "Dropdown Match Select Width",
    },
  },
}
