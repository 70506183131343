import { getDefaultVariableNode, getDefaultTextNode } from "./utils"

export function registerAppVariables(editor, appVariables, callback) {
  editor.ui.registry.addMenuItem("embedAppVariable", {
    text: "Insert Variable",
    onAction: function () {
      editor.windowManager.open({
        title: "Insert Variable",
        body: {
          type: "panel",
          items: [
            {
              type: "selectbox",
              name: "appVariable",
              label: "Choose App Variable",
              items: appVariables.current
                .filter(v => v.id && v.name)
                .map(v => ({
                  value: v.id,
                  text: v.name,
                })),
            },
          ],
        },
        buttons: [
          {
            type: "cancel",
            text: "Cancel",
          },
          {
            type: "submit",
            text: "Insert",
            primary: true,
          },
        ],
        onSubmit: function (dialog) {
          var data = dialog.getData()
          // TODO: consider adding node to widget options rather than directly to the editor
          editor.insertContent(getDefaultVariableNode(data.appVariable))
          dialog.close()
          callback(editor.getBody())
        },
      })
    },
  })
}

export function registerConditionalText(editor) {
  editor.ui.registry.addMenuItem("conditionalText", {
    text: "Insert Conditional Text",
    onAction: function () {
      editor.insertContent(getDefaultTextNode("Conditional Text"))
    },
  })
}
