import React, { useState, useEffect, useCallback } from "react"
import { Tree, Tag } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBoxOpen,
  faListUl,
  faHashtag,
  faFont,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons"

import findAstNodeByKey from "../../lib/findAstNodeByKey"
import { buildTreeData, getFirstTwoLevelsKeys } from "../../lib/treeUtils"

const SchemaTreeView = props => {
  const { schemaText, onNodeSelect } = props
  const [treeData, setTreeData] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([])

  useEffect(() => {
    const data = buildTreeData(schemaText, traverseNode)
    setTreeData(data)
    setExpandedKeys(keys => {
      if (keys.length === 0) {
        return getFirstTwoLevelsKeys(data)
      }
      return keys
    })
  }, [schemaText])

  const handleSelect = useCallback(
    (keys, event) => {
      const { node } = event
      if (onNodeSelect && node.position) {
        onNodeSelect(node.position)
      }
    },
    [onNodeSelect]
  )

  const handleExpand = useCallback(expandedKeysValue => {
    setExpandedKeys(expandedKeysValue)
  }, [])

  return (
    <Tree
      onExpand={handleExpand}
      onSelect={handleSelect}
      treeData={treeData}
      showLine
      blockNode
      expandedKeys={expandedKeys}
      defaultExpandAll={false}
    />
  )
}

export default SchemaTreeView

const SCHEMA_TYPE_COLOR = "blue"
const COMPONENT_COLOR = "green"
const CONDITION_COLOR = "red"

// Function to traverse a node
const traverseNode = (node, astNode, schemaKey, path = "", conditions = []) => {
  if (!node || typeof node !== "object") return null

  // Extract basic schema info
  const { type: schemaType, title, metadata } = node
  const nodeType = Array.isArray(schemaType)
    ? schemaType.join(" | ")
    : schemaType

  // Determine the icon for the schema type
  const icon = getIconForType(schemaType)

  // Include component name if available
  const componentName = metadata?.component

  // Use schemaKey if title is missing
  // const displayTitle = title || schemaKey || nodeType
  const displayTitle = schemaKey

  // Generate color for schema type and component
  const typeTag = createColorTag(nodeType, SCHEMA_TYPE_COLOR)
  // const titleTag = title ? createColorTag(title, TITLE_COLOR) : null
  const componentTag = componentName
    ? createColorTag(componentName, COMPONENT_COLOR)
    : null

  // Concatenate condition descriptions
  const conditionTags = conditions.length
    ? conditions.map(c => createColorTag(c, CONDITION_COLOR))
    : null

  // Build the node title
  const nodeTitle = (
    <div>
      <FontAwesomeIcon icon={icon} style={{ marginRight: 5 }} />
      <b>
        {displayTitle} {title ? `(${title})` : ""}
        {/* {titleTag} */}
      </b>
      <br />
      {typeTag}
      {componentTag}
      {conditionTags ? (
        <>
          <br />
          {conditionTags}
        </>
      ) : null}
    </div>
  )

  // Get position from AST node
  const position = astNode?.loc
    ? { start: astNode.loc.start, end: astNode.loc.end }
    : null

  // Generate a unique key by hashing the path and conditions
  const nodeKey = generateUniqueKey(path, conditions)

  // Initialize the current node
  let currentNode = {
    key: nodeKey,
    title: nodeTitle,
    children: [],
    position,
  }

  // Process conditionals
  const conditionalChildren = processConditionals(
    node,
    astNode,
    path,
    schemaKey,
    conditions
  )

  if (conditionalChildren.length > 0) {
    currentNode.children.push(...conditionalChildren)
  }

  // Process properties
  const propertiesChildren = processProperties(node, astNode, path, conditions)

  if (propertiesChildren.length > 0) {
    currentNode.children.push(...propertiesChildren)
  }

  // Process items
  const itemsChildren = processItems(node, astNode, path, conditions)

  if (itemsChildren.length > 0) {
    currentNode.children.push(...itemsChildren)
  }

  return currentNode
}

// Function to process conditionals
const processConditionals = (node, astNode, path, schemaKey, conditions) => {
  const conditionalKeys = [
    "if",
    "then",
    "else",
    "dependencies",
    "allOf",
    "anyOf",
    "oneOf",
  ]

  let children = []
  // Process conditionals first
  for (const key of conditionalKeys) {
    if (node[key]) {
      const conditionalAstNode = findAstNodeByKey(astNode, key)

      if (key === "dependencies") {
        // Handle dependencies
        for (const [depKey, depValue] of Object.entries(node[key])) {
          const depAstNode = findAstNodeByKey(conditionalAstNode, depKey)
          const conditionDesc = `Depends on "${depKey}"`
          const childNode = traverseNode(
            depValue,
            depAstNode,
            schemaKey,
            path,
            [...conditions, conditionDesc]
          )
          if (childNode) {
            children.push(...(childNode.children || [childNode]))
          }
        }
      } else if (Array.isArray(node[key])) {
        // Handle combinators like allOf, anyOf, oneOf
        const combinerAstNode = findAstNodeByKey(astNode, key)
        node[key].forEach((item, index) => {
          const itemAstNode = combinerAstNode?.children[index] || null
          const childNode = traverseNode(
            item,
            itemAstNode,
            schemaKey,
            path,
            conditions
          )
          if (childNode) {
            children.push(...(childNode.children || [childNode]))
          }
        })
      } else if (key === "if") {
        // Handle 'if' condition
        const conditionDesc = extractConditionDescription(node[key])
        const newConditions = [...conditions, conditionDesc]

        // Handle 'then'
        if (node["then"]) {
          const thenAstNode = findAstNodeByKey(astNode, "then")
          const thenNode = traverseNode(
            node["then"],
            thenAstNode,
            schemaKey,
            path,
            newConditions
          )
          if (thenNode) {
            children.push(...(thenNode.children || [thenNode]))
          }
        }

        // Handle 'else'
        if (node["else"]) {
          const elseAstNode = findAstNodeByKey(astNode, "else")
          const elseCondition = `NOT (${conditionDesc})`
          const elseNode = traverseNode(
            node["else"],
            elseAstNode,
            schemaKey,
            path,
            [...conditions, elseCondition]
          )
          if (elseNode) {
            children.push(...(elseNode.children || [elseNode]))
          }
        }
      }
      // After processing conditionals, skip to next iteration
      continue
    }
  }

  return children
}

// Function to process properties
const processProperties = (node, astNode, path, conditions) => {
  const children = []
  if (node.properties) {
    const propertiesAstNode = findAstNodeByKey(astNode, "properties")
    Object.entries(node.properties).forEach(([key, value]) => {
      const propertyAstNode = findAstNodeByKey(propertiesAstNode, key)
      const childPath = `${path}/${key}`
      const childNode = traverseNode(
        value,
        propertyAstNode,
        key,
        childPath,
        conditions
      )
      if (childNode) {
        children.push(childNode)
      }
    })
  }

  return children
}

// Function to process items
const processItems = (node, astNode, path, conditions) => {
  const children = []
  const { type: schemaType } = node
  const nodeType = Array.isArray(schemaType)
    ? schemaType.join(" | ")
    : schemaType || "unknown"

  if (nodeType === "array" && node.items) {
    const itemsAstNode = findAstNodeByKey(astNode, "items")
    const childPath = `${path}/items`
    const childNode = traverseNode(
      node.items,
      itemsAstNode,
      "items",
      childPath,
      conditions
    )
    if (childNode) {
      children.push(childNode)
    }
  }

  return children
}

// Helper function to extract condition description
const extractConditionDescription = ifNode => {
  // For simplicity, we'll handle basic 'properties' with 'enum'
  if (ifNode.properties) {
    const conditions = Object.entries(ifNode.properties).map(
      ([propName, propSchema]) => {
        if (propSchema.enum) {
          return `${propName} == ${propSchema.enum.join(" | ")}`
        }
        return ""
      }
    )
    return conditions.filter(Boolean).join(" & ")
  }
  return "Condition"
}

// Helper function to generate a unique key
const generateUniqueKey = (path, conditions) => {
  const conditionKey = conditions.length ? conditions.join("|") : ""
  return `${path}::${conditionKey}`
}

// Helper function to get icon for schema type
const getIconForType = type => {
  const typeStr = Array.isArray(type) ? type[0] : type
  switch (typeStr) {
    case "object":
      return faBoxOpen
    case "array":
      return faListUl
    case "string":
      return faFont
    case "number":
    case "integer":
      return faHashtag
    default:
      return faQuestionCircle
  }
}

// Helper function to create color tag
const createColorTag = (name, color) => {
  return (
    <Tag color={color} style={{ marginLeft: 5 }}>
      {name}
    </Tag>
  )
}
