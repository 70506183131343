import React, { useRef, useMemo, useCallback, useEffect, useState } from "react"
import { debounce } from "lodash"
import { Editor } from "@tinymce/tinymce-react"
import { useSelector } from "react-redux"

import { useWidgetContext } from "../../hooks"
import { registerAppVariables, registerConditionalText } from "./plugins"
import { defaultInitOptions, DBAI_NODE_CLASSNAME } from "../../lib/tinyMce"
import { selectEditingWidgetId, selectAppVariables } from "../../selectors/app"
import {
  handleRemoveNode,
  preventBreakingNode,
  handleNodeSelection,
  updateVariableValues,
} from "./utils"

const TinyEditor = props => {
  const { onChange, onMount, widgetId, value: formValue } = props
  const editorRef = useRef()
  const saveInFlight = useRef(false)
  const initialValue = useRef(formValue || "")
  const formValueRef = useRef(formValue)
  const { appConfig } = useWidgetContext()
  const editingWidgetId = useSelector(selectEditingWidgetId)
  const [value, setValue] = useState(formValue)
  const [editor, setEditor] = useState(null)
  const [isActive, setIsActive] = useState(false)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const save = useCallback(
    debounce(v => {
      onChange(v)
      saveInFlight.current = false
    }, 200),
    [onChange]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleEditorChange = useCallback(
    value => {
      // Prevent saving if the value hasn't changed. This can happen if the value is changed externally
      if (value === formValueRef.current) return
      saveInFlight.current = true
      setValue(value)
      save(value)
    },
    [save]
  )

  useEffect(() => {
    formValueRef.current = formValue
  }, [formValue])

  useEffect(() => {
    // update the form value if it changes externally
    if (!saveInFlight.current && formValue !== value) {
      setValue(formValue)
    }
  }, [formValue, value])

  const appVariables = useSelector(selectAppVariables)
  const appVariablesRef = useRef(appVariables)

  useEffect(() => {
    appVariablesRef.current = appVariables
  }, [appVariables])

  useEffect(() => {
    if (editingWidgetId === widgetId && !isActive) {
      setIsActive(true)
      setTimeout(() => {
        if (editor && editor.selection) {
          editor.selection.select(editor.getBody(), true)
          editor.selection.collapse(false)
          editor.selection.scrollIntoView()
          editor.focus()
        }
      }, 200)
    }
    if (editingWidgetId !== widgetId) {
      setIsActive(false)
    }
  }, [editingWidgetId, widgetId, editor, isActive, setIsActive])

  const addAppVariableValues = useCallback(
    doc => {
      updateVariableValues(doc, appVariables)
    },
    [appVariables]
  )

  const editorInit = useMemo(() => {
    return {
      ...defaultInitOptions,
      setup: editor => {
        registerAppVariables(editor, appVariablesRef, addAppVariableValues)
        registerConditionalText(editor, appVariablesRef)

        setEditor(editor)

        editor.on(
          "NodeChange",
          handleNodeSelection(editor, DBAI_NODE_CLASSNAME)
        )
        editor.on("keydown", preventBreakingNode(editor, DBAI_NODE_CLASSNAME))
        editor.on("keydown", handleRemoveNode(editor, DBAI_NODE_CLASSNAME))
      },
      init_instance_callback: () => {
        onMount && onMount(editorRef)
      },
    }
  }, [addAppVariableValues, onMount])

  if (!appConfig?.tinyMCEApiKey) return null
  return (
    <Editor
      inline
      id={widgetId}
      ref={editorRef}
      init={editorInit}
      value={value}
      apiKey={appConfig.tinyMCEApiKey}
      disabled={!isActive}
      initialValue={initialValue.current}
      onEditorChange={handleEditorChange}
    />
  )
}

export default React.memo(TinyEditor)
