import React from "react"
import PropTypes from "prop-types"

import { Table, DateFormatter } from "@dbai/ui-staples"

import BadgeFormatter from "components/shared/BadgeFormatter"

const precedenceMap = {
  AVAILABLE: "success",
  BUILDING: "secondary",
  DEV: "danger",
}

const RadioFormatter = ({ value, row, name, onSelect }) => {
  return (
    <input
      type="radio"
      name={name}
      value={value}
      disabled={row.status !== "AVAILABLE"}
      className="form-check"
      onChange={() => onSelect(row)}
    />
  )
}
const renderImage = ({ value }) => {
  const imageName = value.split(":")[1] || value
  return imageName
}
const PackagesTable = props => {
  const { packages = [], onSelect = () => {} } = props

  return (
    <Table rows={packages}>
      <Table.Column field="id" name="">
        <RadioFormatter name="packageId" onSelect={onSelect} />
      </Table.Column>
      <Table.Column field="status">
        <BadgeFormatter precedenceMap={precedenceMap} />
      </Table.Column>
      <Table.Column field="createdAt" sortable>
        <DateFormatter format="YYYY-MM-D hh:mm:ss" />
      </Table.Column>
      <Table.Column field="image" sortable render={renderImage} />
    </Table>
  )
}

PackagesTable.propTypes = {
  packages: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func,
}

export default PackagesTable
