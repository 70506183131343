import React from "react"

import Select from "../../shared/Select"
import { getSelectMode } from "../../utils"
import { useFormFieldDropdownOptions } from "../../hooks"
import { withFormFieldWrapper } from "../../FormFieldWrapper"
import ColumnValueSelect from "../ColumnValueSelect"

const OptionSetSelect = props => {
  const { schema, parentName, ...rest } = props
  const options = useFormFieldDropdownOptions(schema, parentName)
  return <Select {...rest} options={options} />
}

const EnumSelect = props => {
  return <Select {...props} />
}

const LocalSelectCore = React.memo(props => {
  const { schema } = props
  const { nullable, metadata = {} } = schema
  const { listPath, optionsSource } = metadata
  const mode = getSelectMode(schema)

  if (optionsSource === "datasetColumn") {
    return <ColumnValueSelect {...props} />
  }

  if (listPath) {
    return <OptionSetSelect allowClear={nullable} mode={mode} {...props} />
  }

  return <EnumSelect allowClear={nullable} mode={mode} {...props} />
})

const LocalSelect = withFormFieldWrapper(LocalSelectCore)
LocalSelect.Core = LocalSelectCore
export default LocalSelect
