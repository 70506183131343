import { GET_DATASET_AND_COUNT } from "@dbai/ui-staples/src/queries/data"

import { getSelection } from "../../lib/conditionalFormat"

const fetchDataset =
  ({ datasetId, column, aggregate, percentile }) =>
  (dispatch, getState, { client }) => {
    if (!column || !aggregate) return Promise.resolve()

    const state = getState()
    const cname = state.currentCustomer.customer?.normalizedName
    const select = [getSelection({ column, aggregate, percentile })]

    return client
      .query({
        query: GET_DATASET_AND_COUNT,
        variables: {
          cname,
          datasetId,
          queryOptions: { select },
        },
        notifyOnNetworkStatusChange: true,
      })
      .then(({ loading, data, error }) => {
        return data.customer.dataset.data[0]
      })
      .catch(err => {
        console.error("Error fetching dataset:", err)
      })
  }

const stringComparatorMap = {
  "=": (a, b) => b.includes(a),
  "!=": (a, b) => !b.includes(a),
  default: () => false,
}

const numberComparatorMap = {
  ">": (a, b) => a > parseFloat(b),
  "<": (a, b) => a < parseFloat(b),
  "=": (a, b) => a === parseFloat(b),
  "!=": (a, b) => a !== parseFloat(b),
  ">=": (a, b) => a >= parseFloat(b),
  "<=": (a, b) => a <= parseFloat(b),
  default: () => false,
}

const compareConditionValues = (
  { operator, conjunction },
  leftHand,
  rightHand,
  prev
) => {
  const leftHandType = typeof leftHand
  const comparatorMap =
    leftHandType === "string" ? stringComparatorMap : numberComparatorMap
  const compare = comparatorMap[operator] || comparatorMap.default
  if ([null, undefined].includes(prev)) return compare(leftHand, rightHand)
  if (conjunction === "and") return prev && compare(leftHand, rightHand)
  return prev || compare(leftHand, rightHand)
}

const processConditionOperand = async (dispatch, operand, appVariables) => {
  const { value, type, aggregate, percentile, datasetId, operandType } = operand
  switch (operandType) {
    case "datasetColumn":
      if (datasetId && value && type && aggregate) {
        const results = await dispatch(
          fetchDataset({
            datasetId,
            column: value,
            type,
            aggregate,
            percentile,
          })
        )
        return results ? results.rows[0][0] : null
      }
      return null
    case "appVariable":
      const { value: appVariableValue } =
        appVariables.find(av => av.id === value) || {}
      return appVariableValue
    case "staticValue":
      return value
    default:
      return null
  }
}

const processCondition = async (dispatch, condition, appVariables) => {
  const {
    sourceType,
    sourceValue,
    sourcePercentile,
    sourceValueType,
    sourceAggregate,
    sourceDatasetId,

    value,
    aggregate,
    valueType,
    datasetId,
    type,
    percentile,
  } = condition
  const leftHand = await processConditionOperand(
    dispatch,
    {
      value: sourceValue,
      type: sourceValueType,
      operandType: sourceType,
      aggregate: sourceAggregate,
      datasetId: sourceDatasetId,
      percentile: sourcePercentile,
    },
    appVariables
  )
  const rightHand = await processConditionOperand(
    dispatch,
    {
      value,
      aggregate,
      datasetId,
      percentile,
      type,
      column: value,
      operandType: valueType,
    },
    appVariables
  )
  return { leftHand, rightHand }
}

const processConditions = (dispatch, conditions, appVariables) => {
  return conditions.reduce(async (acc, condition) => {
    const { leftHand, rightHand } = await processCondition(
      dispatch,
      condition,
      appVariables
    )

    // skip if any of the values are null
    if (
      [null, undefined].includes(leftHand) ||
      [null, undefined].includes(rightHand)
    ) {
      return acc
    }

    const { operator, conjunction } = condition
    return compareConditionValues(
      { operator, conjunction },
      leftHand,
      rightHand,
      acc
    )
  }, null)
}

export default processConditions
