import { useMemo } from "react"
import { useQuery } from "@apollo/client"

import { GET_ME } from "../queries/users"
import useCurrentCustomer from "./useCurrentCustomer"
import { checkForDBAdmin } from "../lib/userHelpers"

const dbName = "delta-bravo"
const allow = "ALLOW"

// Do we maybe want to inject this on the backend instead of checking
// alternate customers on the client side?
const superAdmin = {
  name: "Super Admin",
  policies: [
    {
      name: "Super Admin",
      rule: {
        effect: allow,
        actions: ["*"],
        subjects: ["*"],
      },
    },
  ],
}

/*
 * Returns an array of all the roles that can be found for the current user
 * under the current customer. Adds the delta-bravo Admin role if the current
 * user is in a different customer but is an admin in delta-bravo.
 */
const useCustomerRoles = customer => {
  const [currentCustomer] = useCurrentCustomer()
  const { data, loading } = useQuery(GET_ME)

  const customerRoles = useMemo(() => {
    const customers = data?.me?.customers || []

    if (customer === null && currentCustomer === null) {
      return checkForDBAdmin(customers) ? [superAdmin] : []
    }

    const customerId = customer?.id || currentCustomer?.id
    const targetCustomer = customers.find(c => c.id === customerId) || null
    if (!targetCustomer && !checkForDBAdmin(customers)) return []

    if (targetCustomer?.normalizedName !== dbName) {
      return checkForDBAdmin(customers)
        ? [...(targetCustomer?.roles || []), superAdmin]
        : targetCustomer?.roles || []
    }

    return targetCustomer.roles || []
  }, [customer, currentCustomer, data])

  return [customerRoles, loading]
}

export default useCustomerRoles
