import React, { memo, useState, useEffect, useMemo } from "react"
import { isEqual } from "lodash"
import styled from "styled-components"
import { Flex, Space, Typography } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import KeyValue from "./KeyValue"
import TabSections from "./TabSections"
import LayoutWrapper from "../../LayoutWrapper"
import { useFormData } from "../../hooks"
import JSONSchemaField from "../../JSONSchemaField"
import CollapsedSections from "./CollapsedSections"
import {
  isSchemaHidden,
  getResolvedSchema,
  deconstructObjectProperties,
} from "../../utils"

const { Title } = Typography

const StyledTitle = styled(Title)`
  margin-top: 4px;
`

const useSectionlessFields = ({
  name,
  path,
  value,
  layout,
  parentSchema,
  sectionlessFields,
  collapseHeaderLevel,
}) => {
  const [fields, setFields] = useState([])

  useEffect(() => {
    const schemas = sectionlessFields.reduce((acc, [key, schema]) => {
      const resolvedSchema = getResolvedSchema(
        schema,
        parentSchema,
        value?.[key]
      )

      if (isSchemaHidden(resolvedSchema, value?.[key], path)) {
        return acc
      }

      return [...acc, [key, resolvedSchema]]
    }, [])

    if (!isEqual(schemas, fields)) {
      setFields(schemas)
    }
  }, [sectionlessFields, value, parentSchema, path, fields])

  return useMemo(() => {
    // if there is only a single field, then remove the margin bottom
    const singleFieldStyles = fields.length === 1 ? { margin: "0 1em 0 0" } : {}
    return fields.map(([key, schema], idx) => {
      const { metadata = {} } = schema
      const { wrapperStyles = {} } = metadata
      return (
        <LayoutWrapper
          key={key}
          path={path}
          name={`${name}.${key}`}
          schema={schema}
          layout={layout}
          parentSchema={parentSchema}
        >
          <JSONSchemaField
            path={path}
            name={name}
            schema={schema}
            schemaKey={key}
            parentSchema={parentSchema}
            collapseHeaderLevel={collapseHeaderLevel}
            wrapperStyles={{ ...singleFieldStyles, ...wrapperStyles }}
          />
        </LayoutWrapper>
      )
    }, [])
  }, [fields, path, name, layout, parentSchema, collapseHeaderLevel])
}

const ObjectPropertiesList = memo(props => {
  const {
    name,
    path,
    layout,
    className,
    schema: parentSchema,
    collapseHeaderLevel = 0,
  } = props
  const value = useFormData(name)
  const { metadata = {} } = parentSchema
  const { sectionType, icon, hideLabel = true } = metadata

  const { sectionedFields, sectionlessFields } = useMemo(() => {
    return deconstructObjectProperties(parentSchema)
  }, [parentSchema])

  // get list of rendered sectionless fields to determine whether to render the layout wrapper for the
  // sectionless fields
  const sectionlessFieldsList = useSectionlessFields({
    name,
    path,
    value,
    layout,
    parentSchema,
    sectionlessFields,
    collapseHeaderLevel,
  })

  const SectionedFieldsComp =
    sectionType === "tabs" ? TabSections : CollapsedSections

  return (
    <Flex vertical style={{ width: "100%" }}>
      {!hideLabel && parentSchema.title ? (
        <Space>
          {icon ? <FontAwesomeIcon icon={icon} /> : null}
          <StyledTitle level={5}>{parentSchema.title}</StyledTitle>
        </Space>
      ) : null}
      {sectionlessFieldsList.length ? (
        <LayoutWrapper
          container
          path={path}
          layout={layout}
          schema={parentSchema}
          className={className}
        >
          {sectionlessFieldsList}
        </LayoutWrapper>
      ) : null}
      {sectionedFields.length ? (
        <SectionedFieldsComp {...props} sectionedFields={sectionedFields} />
      ) : null}
    </Flex>
  )
})

const ObjectList = props => {
  const { schema } = props
  if (!schema.properties || schema.metadata?.component === "KeyValueMap") {
    return <KeyValue {...props} />
  }
  return <ObjectPropertiesList {...props} />
}

export default memo(ObjectList)
