import React, { useState, useEffect, useMemo, useCallback } from "react"
import { Button, Tabs } from "antd"
import styled from "styled-components"
import { useQuery } from "@apollo/client"
import { useDispatch } from "react-redux"
import { Route, Switch } from "react-router-dom"
import { faFileImport } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  SidePane,
  PageSpinner,
  ErrorMessage,
  AuthBoundary,
  TableWithFolders,
  useCheckForDBAdmin,
} from "@dbai/ui-staples"

import Schedules from "./Schedules"
import { useTagColumn } from "hooks"
import NewWorkflow from "./NewWorkflow"
import PublishCheckbox from "components/pages/Workflows/WorflowsPublishCheckbox"
import WorkflowActions from "./WorkflowActions"
import { actions } from "reducers/notebooksReducer"
import Actions from "components/pages/Workflows/Actions"
import Components from "components/pages/Components"
import withCustomer from "components/shared/withCustomer"
import Packages from "components/pages/Workflows/Packages"
import { GET_WORKFLOWS, GET_WORKFLOW_METADATA } from "queries"
import WorkflowsContent from "components/pages/Workflows/shared/WorkflowsContent"
import IndexHeader, {
  workflowPaths,
} from "components/pages/Workflows/shared/IndexHeader"
import PublishedWorkflowPicker from "components/pages/Workflows/shared/WorkflowSelector"
const ImportPrompt = styled.span`
  margin-left: 5px;
`

const nonDBColumnOrder = ["name", "tags", "createdAt", "updatedAt", "actions"]

const publishedColumn = {
  title: "Published",
  dataIndex: "published",
  key: "published",
  width: 100,
  render: props => {
    return <PublishCheckbox {...props} />
  },
}
const dbColumnOrder = [
  "name",
  "tags",
  "createdAt",
  "updatedAt",
  "published",
  "actions",
]

const tagColumnOptions = {
  type: "workflows",
  query: GET_WORKFLOW_METADATA,
}

const staticTableOpts = {
  defaultSort: {
    column: "updatedAt",
    order: "descend",
  },
  pagination: {
    defaultPageSize: 20,
    hideOnSinglePage: true,
  },
}

const WorkflowsTable = props => {
  const { customer } = props
  const dispatch = useDispatch()
  const tags = useTagColumn(tagColumnOptions)
  const isDBAdmin = useCheckForDBAdmin()

  useEffect(() => {
    dispatch(actions.loadNotebooks(customer.normalizedName))
  }, [customer.normalizedName, dispatch])

  useEffect(() => {
    const notebookStatusWatcher = setInterval(
      () => dispatch(actions.loadNotebooks(customer.normalizedName)),
      10000
    )
    return () => clearInterval(notebookStatusWatcher)
  }, [customer.normalizedName, dispatch])

  const columnOrder = useMemo(
    () => (isDBAdmin ? dbColumnOrder : nonDBColumnOrder),
    [isDBAdmin]
  )
  const allColumns = useMemo(() => {
    return [tags, publishedColumn]
  }, [tags])

  const cname = customer.normalizedName
  const variables = { cname }
  const { error, loading, data } = useQuery(GET_WORKFLOWS, {
    variables,
  })

  if (loading) return <PageSpinner />
  if (error) return <ErrorMessage error={error} />

  return (
    <TableWithFolders
      columns={allColumns}
      folderType="workflows"
      Actions={WorkflowActions}
      columnOrder={columnOrder}
      dataSource={data.customer.workflows}
      rowLink={row => `/${cname}/workflows/${row.id}`}
      {...staticTableOpts}
    />
  )
}

const Workflows = props => {
  const { customer } = props
  const [showNew, setShowNew] = useState(false)
  const [isImport, setIsImport] = useState(false)
  const turnOffNew = () => {
    setIsImport(false)
    setShowNew(false)
  }

  const cname = customer.normalizedName

  const variables = useMemo(() => ({ cname }), [cname])
  const refetchQueries = useMemo(
    () => [{ query: GET_WORKFLOWS, variables }],
    [variables]
  )

  const PickerTabs = useCallback(
    props => {
      const items = [
        {
          key: "newWorkflow",
          label: "Make From Scratch",
          children: (
            <NewWorkflow
              refetchQueries={refetchQueries}
              customerId={customer.id}
              isImport={isImport}
              afterSubmit={() => turnOffNew()}
            />
          ),
        },
        {
          key: "workflowTemplates",
          label: "Make From Template",
          children: (
            <PublishedWorkflowPicker
              customer={customer}
              refetchQueries={refetchQueries}
              onClose={() => turnOffNew()}
            />
          ),
        },
      ]
      return <Tabs defaultActiveKey="newWorkflow" items={items} />
    },
    [customer, isImport, refetchQueries]
  )

  return (
    <>
      <IndexHeader>
        <AuthBoundary subject="workflows" action="CREATE">
          <Button
            type="dashed"
            onClick={() => {
              setIsImport(true)
              setShowNew(true)
            }}
          >
            <FontAwesomeIcon icon={faFileImport} />
            <ImportPrompt>IMPORT NOTEBOOK</ImportPrompt>
          </Button>
          <Button type="primary" onClick={() => setShowNew(true)}>
            + NEW WORKFLOW
          </Button>
        </AuthBoundary>
      </IndexHeader>
      <WorkflowsContent>
        <WorkflowsTable customer={customer} refetchQueries={refetchQueries} />
        <SidePane
          title="Create Workflow"
          isVisible={showNew}
          width="30vw"
          onCloseClicked={() => turnOffNew()}
          mask
        >
          <PickerTabs />
        </SidePane>
      </WorkflowsContent>
    </>
  )
}

const WorkflowsIndex = ({ customer }) => {
  return (
    <AuthBoundary subject="workflows" action="LIST">
      <Switch>
        <Route path={workflowPaths.schedules}>
          <Schedules customer={customer} />
        </Route>

        <Route path={workflowPaths.library}>
          <Actions customer={customer} />
        </Route>

        <Route path={workflowPaths.components}>
          <Components />
        </Route>

        <Route path={workflowPaths.packages}>
          <Packages customer={customer} />
        </Route>

        <Route path={workflowPaths.workflows}>
          <Workflows customer={customer} />
        </Route>
      </Switch>
    </AuthBoundary>
  )
}

export default withCustomer(WorkflowsIndex)
