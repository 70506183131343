import React, { useState, useCallback, useEffect } from "react"
import { Tree } from "antd"

import findAstNodeByKey from "../../lib/findAstNodeByKey"
import { buildTreeData, getFirstTwoLevelsKeys } from "../../lib/treeUtils"

const SpecTreeView = props => {
  const { specText, onNodeSelect } = props
  const [treeData, setTreeData] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([])

  useEffect(() => {
    const data = buildTreeData(specText, traverseNode)
    setTreeData(data)
    setExpandedKeys(keys => {
      if (keys.length === 0) {
        return getFirstTwoLevelsKeys(data)
      }
      return keys
    })
  }, [specText])

  const handleSelect = (keys, event) => {
    const { node } = event
    if (onNodeSelect && node.position) {
      onNodeSelect(node.position)
    }
  }

  const handleExpand = useCallback(expandedKeysValue => {
    setExpandedKeys(expandedKeysValue)
  }, [])

  return (
    <Tree
      onExpand={handleExpand}
      onSelect={handleSelect}
      treeData={treeData}
      showLine
      blockNode
      expandedKeys={expandedKeys}
      defaultExpandAll={false}
    />
  )
}

export default SpecTreeView

const traverseNode = (node, astNode, path = "") => {
  if (node === null || typeof node !== "object") {
    const position = astNode?.loc
      ? { start: astNode.loc.start, end: astNode.loc.end }
      : null

    const nodeKey = path || "root"
    const nodeTitle = `${path.split(".").pop() || "root"}: ${JSON.stringify(node)}`

    return {
      key: nodeKey,
      title: nodeTitle,
      position,
      isLeaf: true,
    }
  }

  let children = []

  if (Array.isArray(node)) {
    node.forEach((item, index) => {
      const itemAstNode = astNode?.children[index]?.value || null
      const childNode = traverseNode(item, itemAstNode, `${path}[${index}]`)
      if (childNode) {
        children.push(childNode)
      }
    })
  } else {
    Object.entries(node).forEach(([key, value]) => {
      const childAstNode = findAstNodeByKey(astNode, key)
      const childNode = traverseNode(value, childAstNode, `${path}.${key}`)
      if (childNode) {
        children.push(childNode)
      }
    })
  }

  const position = astNode?.loc
    ? { start: astNode.loc.start, end: astNode.loc.end }
    : null

  const nodeKey = path || "root"
  const nodeTitle = path.split(".").pop() || "root"

  return {
    key: nodeKey,
    title: nodeTitle,
    children: children.length > 0 ? children : undefined,
    position,
  }
}
