import React from "react"
import { useQuery } from "@apollo/client"
import { Redirect, useParams } from "react-router-dom"

import { GET_CUSTOMER, PageSpinner } from "@dbai/ui-staples"

import { useDefaultPath } from "hooks"

const CustomerDefaultPath = props => {
  const { cname } = useParams()
  const { data: customer, loading } = useQuery(GET_CUSTOMER, {
    variables: { cname },
  })
  const [path, loadingDefaultPath] = useDefaultPath(customer)

  if (loading || loadingDefaultPath) return <PageSpinner />
  if (customer) {
    return <Redirect to={`/${cname}/${path}`} />
  }
  return null
}

export default CustomerDefaultPath
