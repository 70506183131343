import React, { useState, useCallback, useMemo } from "react"
import { isArray } from "lodash"

import Select from "../shared/Select"
import { getSelectMode } from "../utils"
import usePresetOptions from "../hooks/usePresetOptions"

const realValues = values =>
  values.filter(v => ![null, undefined, ""].includes(v))

const filterOption = (input, option) => {
  const label = `${option?.label ?? option?.value}`
  return label.toLowerCase().includes(input.toLowerCase())
}

// TODO: consider preventing multi select functionality if the schema is not of type "array"
const getIsMultiSelect = schema => {
  const { isMulti } = schema.metadata || {}
  return isMulti || schema.type === "array"
}

const getSelectedValue = (schema, value) => {
  const isMultiSelect = getIsMultiSelect(schema)
  if (isArray(value)) {
    const filteredValues = realValues(value)
    if (isMultiSelect) return filteredValues
    return filteredValues.length > 1 ? filteredValues[1] : filteredValues[0]
  }
  return value
}

const getRenderValue = (schema, value) => {
  const mode = getSelectMode(schema)
  if (["tags", "multiple"].includes(mode)) {
    return realValues(isArray(value) ? value : [value])
  }
  return getSelectedValue(schema, value)
}

const SelectWithPresets = props => {
  const { schema, onChange, parentName, parentSchema, ...rest } = props
  const [open, setOpen] = useState(false)
  const { metadata } = schema
  const { valuePresets } = metadata

  const { loading, fetchMore, refetch, error, options, column } =
    usePresetOptions({
      schema,
      parentSchema,
      parentName,
      metadata: {
        ...schema.metadata,
        // setting showPresets to false will prevent the usePresetOptions hook from running.
        showPresets: !valuePresets || valuePresets !== "none",
      },
      fetchPolicy: "cache-and-network",
    })

  const onSearch = useCallback(
    value => {
      fetchMore({
        where: [{ column, cast: "text", op: "like", value: `${value}%` }],
      })
    },
    [fetchMore, column]
  )

  const handleChange = useCallback(
    value => {
      fetchMore({ where: [] })
      onChange(value)
    },
    [fetchMore, onChange]
  )

  const toggleOpen = useCallback(
    visible => {
      // if there are no presets, we don't want to show the dropdown
      if (valuePresets === "none") {
        setOpen(false)
        return
      }
      visible && refetch && refetch()
      setOpen(visible)
    },
    [valuePresets, refetch]
  )

  // if there are no presets, we don't want to show the dropdown arrow icon
  if (error) return null

  return (
    <Select
      {...rest}
      showSearch
      open={open}
      options={options}
      loading={loading}
      onSearch={onSearch}
      onChange={handleChange}
      suffixIcon={undefined} // show the dropdown arrow icon
      filterOption={filterOption}
      onDropdownVisibleChange={toggleOpen}
    />
  )
}

const ColumnValueSelect = props => {
  const {
    schema,
    onChange,
    parentName,
    parentSchema,
    value: _value,
    ...rest
  } = props
  const { metadata, nullable } = schema
  const { valuePresets = "none" } = metadata
  const mode = getSelectMode(schema)

  const handleChange = useCallback(
    value => {
      onChange(getSelectedValue(schema, value))
    },
    [schema, onChange]
  )

  const value = useMemo(() => {
    return getRenderValue(schema, _value)
  }, [schema, _value])

  if (valuePresets && valuePresets !== "none") {
    return (
      <SelectWithPresets
        {...props}
        mode={mode}
        value={value}
        allowClear={nullable}
        onChange={handleChange}
        suffixIcon={null}
      />
    )
  }

  return (
    <Select
      mode={mode}
      value={value}
      allowClear={nullable}
      onChange={handleChange}
      suffixIcon={null} // hide dropdown arrow icon
      {...rest}
    />
  )
}

export default ColumnValueSelect
