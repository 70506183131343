import React, { useRef, useCallback, useEffect, useState } from "react"
import { isEqual } from "lodash"
import { useDispatch, useSelector } from "react-redux"

import { useWidgetContext } from "../../hooks"
import { actions } from "../../reducers/appReducer"
import TextContainer from "../../JSONSchemaForm/fields/TextContainer"
import JSONSchemaForm from "../../JSONSchemaForm"
import { getWidgetFormId } from "../../lib/widgetEditor"
import {
  getEditor,
  getConfigurableNodes,
  DBAI_NODE_CLASSNAME,
} from "../../lib/tinyMce"
import FormFieldWrapper from "../../JSONSchemaForm/FormFieldWrapper"
import { selectEditingWidgetId } from "../../selectors/app"
import {
  useConditionalText,
  useAppVariableValues,
  useConditionalFormatting,
} from "./hooks"
import { getDefaultVariableNode, getDefaultTextNode } from "./utils"
import TinyEditor from "./TinyEditor"

const TextEditor = React.memo(props => {
  const { widget, mounted, widgetId, ...rest } = props
  const formId = getWidgetFormId(widgetId)
  const dispatch = useDispatch()
  const widgetRef = useRef(widget)
  const editingWidgetId = useSelector(selectEditingWidgetId)
  const { widgetSchema } = useWidgetContext()

  const handleFormChange = useCallback(
    ({ options, id }) => {
      return dispatch(
        actions.setWidgetOptionsWithSync({ value: options, widgetId: id })
      )
    },
    [dispatch]
  )

  const openEditor = useCallback(
    e => {
      if (editingWidgetId !== widgetId) {
        dispatch(actions.editWidget({ widgetId }))
      }
    },
    [dispatch, widgetId, editingWidgetId]
  )

  // sycnhronizes editor with the current widget options
  const syncEditor = useCallback(() => {
    const editor = getEditor(widgetId)
    if (!editor) return
    // Get all app variable nodes from text editor
    const body = editor.getBody()

    if (!body) return

    const nodesInEditor = getConfigurableNodes(
      Array.from(body.querySelectorAll(`.${DBAI_NODE_CLASSNAME}`))
    )
    const nodesInWidget = widget.options.nodes

    // Update the nodes if the computed nodes are differnet from the existing nodes
    if (!isEqual(nodesInEditor, nodesInWidget)) {
      nodesInEditor.forEach(node => {
        if (nodesInWidget.find(v => v.nodeId === node.nodeId)) return
        // remove node from editor
        const nodeElement = body.querySelector(
          `[data-node-id="${node.nodeId}"]`
        )
        nodeElement?.remove()
      })

      nodesInWidget.forEach(node => {
        const { nodeType, nodeId } = node
        if (nodesInEditor.find(v => v.nodeId === nodeId)) return
        if (!nodeId || !nodeType) return
        const textNode =
          nodeType === "appVariable"
            ? getDefaultVariableNode(node)
            : getDefaultTextNode(node)
        editor.insertContent(textNode)
      })
    }
  }, [widgetId, widget.options.nodes])

  useEffect(() => {
    widgetRef.current = widget
    syncEditor()
  }, [widget, syncEditor])

  return (
    <Container
      editable={true}
      widget={widget}
      mounted={mounted}
      onClick={openEditor}
      vPosition={widget.options.vPosition}
    >
      <JSONSchemaForm.Provider
        value={widget}
        formId={formId}
        schema={widgetSchema}
        onFormChange={handleFormChange}
      >
        <FormFieldWrapper noStyle schemaKey="text" path="./options">
          <TinyEditor {...rest} widgetId={widgetId} />
        </FormFieldWrapper>
      </JSONSchemaForm.Provider>
    </Container>
  )
})

const Container = React.memo(props => {
  const { widget, onClick, editable, children, vPosition, mounted } = props
  const editorRef = useRef()

  useAppVariableValues(editorRef, widget, mounted)
  useConditionalText(editorRef, widget, mounted)
  useConditionalFormatting(editorRef, widget, mounted)

  return (
    <TextContainer
      ref={editorRef}
      onClick={onClick}
      editable={editable}
      vPosition={vPosition}
    >
      {children}
    </TextContainer>
  )
})

const NonEditableText = React.memo(props => {
  const { widget, onMount } = props
  useEffect(() => {
    onMount()
  }, [onMount])

  return <div dangerouslySetInnerHTML={{ __html: widget.options.text }} />
})

const RichText = props => {
  const { widget, registerOnResize, widgetId } = props
  const { editable } = useWidgetContext()
  const [layoutHeight, setLayoutHeight] = useState()
  const [mounted, setMounted] = useState(false)

  const onResize = useCallback(rect => {
    setLayoutHeight(rect.height)
  }, [])

  useEffect(() => {
    registerOnResize(() => onResize)
  }, [registerOnResize, onResize])

  const onMount = useCallback(() => {
    setMounted(true)
  }, [])

  if (editable) {
    return (
      <TextEditor
        widget={widget}
        mounted={mounted}
        onMount={onMount}
        widgetId={widgetId}
        layoutHeight={layoutHeight}
      />
    )
  }

  return (
    <Container
      widget={widget}
      editable={false}
      mounted={mounted}
      vPosition={widget.options.vPosition}
    >
      <NonEditableText widget={widget} onMount={onMount} />
    </Container>
  )
}

export default React.memo(RichText)
