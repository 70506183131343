import React, { useMemo, useRef, useCallback, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Button, Row, Col, Typography } from "antd"

import { actions } from "../../reducers/appReducer"
import SpecTreeView from "./SpecTreeView"
import JSONSchemaForm from "../../JSONSchemaForm"
import StatusIndicator from "./StatusIndicator"

const validateJSON = specText => {
  try {
    JSON.parse(specText)
    return true
  } catch (e) {
    return false
  }
}

const SpecEditor = () => {
  const cmInstance = useRef()
  const appSpec = useSelector(state => state.app.spec)
  const [status, setStatus] = useState(null)
  const [specText, setSpecText] = useState(
    JSON.stringify(appSpec || {}, null, 2)
  )
  const dispatch = useDispatch()
  const specTextRef = useRef(specText)

  const runValidateJSON = useCallback(specText => {
    const isValid = validateJSON(specText)
    if (isValid) {
      setStatus(null)
      return true
    }
    setStatus("error")
    return false
  }, [])

  const handleCheckSchema = useCallback(() => {
    runValidateJSON(specTextRef.current)
  }, [runValidateJSON])

  const handleChange = useCallback(
    async ({ specText }) => {
      specTextRef.current = specText
      const isValid = runValidateJSON(specText)
      if (isValid) {
        setSpecText(specText)
        dispatch(actions.setSpec(JSON.parse(specText)))
      }
    },
    [dispatch, runValidateJSON]
  )

  const schemaEditorSchema = useMemo(() => {
    return {
      type: "object",
      properties: {
        specText: {
          type: "string",
          title: "Form Schema",
          default: "",
          metadata: {
            onInit: cm => {
              cmInstance.current = cm
            },
            height: "calc(100vh - 200px)",
            hideLabel: true,
            component: "CodeEditor",
            containerStyle: {
              overflowY: "auto",
            },
          },
        },
      },
    }
  }, [])

  const handleNodeSelect = useCallback(position => {
    const codeMirrorInstance = cmInstance.current
    if (codeMirrorInstance && position) {
      const { start, end } = position
      codeMirrorInstance.focus()

      // Convert offsets to CodeMirror positions
      const startPos = codeMirrorInstance.posFromIndex(start.offset)
      const endPos = codeMirrorInstance.posFromIndex(end.offset)

      codeMirrorInstance.setSelection(startPos, endPos)
      codeMirrorInstance.scrollIntoView(startPos, 200)
    }
  }, [])

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Typography.Title level={4}>
          Spec Editor <StatusIndicator status={status} />{" "}
          <Button.Group>
            <Button onClick={handleCheckSchema} type="default">
              Check Spec
            </Button>
          </Button.Group>
        </Typography.Title>
        <JSONSchemaForm
          hideSave
          schema={schemaEditorSchema}
          onFormChange={handleChange}
          initialState={{ specText }}
        />
      </Col>
      <Col span={12}>
        <Typography.Title level={4}>Spec Tree</Typography.Title>
        <div
          style={{
            overflow: "auto",
            height: "calc(100vh - 155px)",
          }}
        >
          <SpecTreeView onNodeSelect={handleNodeSelect} specText={specText} />
        </div>
      </Col>
    </Row>
  )
}

export default SpecEditor
