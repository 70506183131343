export const SPACE_UNICODE = "\u00A0"
export const DBAI_NODE_CLASSNAME = "dbai-node"
export const DBAI_NODE_ID_ATTR = "data-node-id"
export const APP_VARIABLE_CLASSNAME = "app-variable"
export const APP_VARIABLE_ID_ATTR = "data-variable-id"
export const CONDITIONAL_TEXT_CLASSNAME = "conditional-text"

export const getEditor = widgetId =>
  window.tinymce ? tinymce.get(widgetId) : null

export const getConfigurableNodes = nodes => {
  return nodes.reduce((acc, node, index) => {
    const nodeType = node.classList.contains(APP_VARIABLE_CLASSNAME)
      ? "appVariable"
      : "conditionalText"

    const nodeIdValue = node.getAttribute(DBAI_NODE_ID_ATTR)
    return [
      ...acc,
      {
        nodeType,
        nodeId: nodeIdValue,
        text: node.outerText,
      },
    ]
  }, [])
}

const filePickerCallback = (cb, value, meta) => {
  const input = document.createElement("input")
  input.setAttribute("type", "file")
  input.setAttribute("accept", "image/*")

  input.addEventListener("change", e => {
    const file = e.target.files[0]

    const reader = new FileReader()
    reader.addEventListener("load", () => {
      /*
          Note: Now we need to register the blob in TinyMCEs image blob
          registry. In the next release this part hopefully won't be
          necessary, as we are looking to handle it internally.
        */
      const id = "blobid" + new Date().getTime()
      const blobCache = tinymce.activeEditor.editorUpload.blobCache
      const base64 = reader.result.split(",")[1]
      const blobInfo = blobCache.create(id, file, base64)
      blobCache.add(blobInfo)

      /* call the callback and populate the Title field with the file name */
      cb(blobInfo.blobUri(), { title: file.name })
    })
    reader.readAsDataURL(file)
  })

  input.click()
}

export const defaultInitOptions = {
  content_css: false,
  height: "100%",
  width: "100%",
  contextmenu: false,
  menubar: "file edit insert view format table tools help",
  menu: {
    insert: {
      title: "Insert",
      items:
        "embedAppVariable conditionalText | image link media addcomment pageembed template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor tableofcontents | insertdatetime",
    },
  },
  plugins:
    "noneditable image editimage media mediaembed link emoticons lists code codesample formatpainter powerpaste",
  noneditable_noneditable_class: "dbai-node",
  toolbar1:
    "undo redo | " +
    "alignleft aligncenter alignright alignjustify | " +
    "bullist numlist outdent indent",
  toolbar2: "fontfamily fontsizeinput forecolor backcolor formatpainter",
  toolbar_sticky: true,
  powerpaste_allow_local_images: true,
  powerpaste_word_import: "prompt",
  powerpaste_html_import: "prompt",
  extended_valid_elements: "span[class|style],div[class|style]",
  file_picker_types: "image",
  file_picker_callback: filePickerCallback,
  font_family_formats:
    "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Industry=Industry; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;",
  font_size_formats: "8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
}
