import React, { useCallback, useMemo } from "react"
import { useQuery } from "@apollo/client"
import { Col } from "antd"

import {
  Content,
  SidePane,
  PageSpinner,
  ErrorMessage,
  AuthBoundary,
  TableWithFolders,
  ActionsFormatter,
  useCurrentCustomer,
  useCheckForDBAdmin,
} from "@dbai/ui-staples"

import NewComponent from "./NewComponent"
import PublishCheckbox from "./PublishCheckbox"
import { GET_COMPONENTS, DELETE_COMPONENT } from "queries"
import { useTagColumn } from "hooks"

const TableRowActions = props => {
  const [customer] = useCurrentCustomer()
  const { row } = props

  return (
    <ActionsFormatter
      value={row.id}
      confirmDelete={true}
      variables={{ customerId: customer.id }}
      deleteGql={DELETE_COMPONENT}
      refetchQuery={GET_COMPONENTS}
      refetchVariables={{ cname: customer.normalizedName }}
      editPath={`/${customer.normalizedName}/workflows/components/`}
    />
  )
}

const publishedColumn = {
  title: "Published",
  dataIndex: "published",
  key: "published",
  width: 100,
  render: props => {
    return <PublishCheckbox {...props} />
  },
}

const nonDBColumnOrder = ["name", "tags", "createdAt", "updatedAt", "actions"]

// Only difference here is that this includes the "published" row.
const dbColumnOrder = [
  "name",
  "tags",
  "createdAt",
  "updatedAt",
  "published",
  "actions",
]

const tagColumnOptions = {
  type: "components",
  query: GET_COMPONENTS,
}

const ComponentsTable = props => {
  const isDBAdmin = useCheckForDBAdmin()
  const { customer, showNew, onNewClose } = props
  const { normalizedName: cname, id: customerId } = customer
  const variables = { cname }
  const tags = useTagColumn(tagColumnOptions)
  const columnOrder = useMemo(
    () => (isDBAdmin ? dbColumnOrder : nonDBColumnOrder),
    [isDBAdmin]
  )
  const allColumns = useMemo(() => {
    return [tags, publishedColumn]
  }, [tags])
  const {
    error,
    loading,
    data,
    refetch: refetchComponents,
  } = useQuery(GET_COMPONENTS, {
    fetchPolicy: "network-only",
    variables,
  })

  const afterSubmit = useCallback(() => {
    onNewClose(false)
    refetchComponents()
  }, [refetchComponents, onNewClose])

  if (loading) return <PageSpinner />
  if (error) return <ErrorMessage error={error} />

  return (
    <AuthBoundary subject="components" action="LIST">
      <div id="components">
        <Content>
          <Col span={24}>
            <TableWithFolders
              columns={allColumns}
              folderType="components"
              columnOrder={columnOrder}
              Actions={TableRowActions}
              dataSource={data.customer.components || []}
              rowLink={row => `/${cname}/workflows/components/${row.id}`}
            />

            <SidePane
              title="Create Component"
              isVisible={showNew}
              onCloseClicked={onNewClose}
              width="50vw"
              mask
            >
              <NewComponent customerId={customerId} afterSubmit={afterSubmit} />
            </SidePane>
          </Col>
        </Content>
      </div>
    </AuthBoundary>
  )
}

export default ComponentsTable
