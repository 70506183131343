import React, { useCallback } from "react"
import { Card } from "antd"
import { Link } from "react-router-dom"

import Input from "../Form/Input"
import SoloCard from "../SoloCard"
import ApiForm from "../Form/ApiForm"
import { SIGNIN } from "../../queries/auth"
import useCurrentUser from "../../hooks/useCurrentUser"

const Login = () => {
  const [, setCurrentUser] = useCurrentUser()

  const handleSubmit = useCallback(
    ({ data }) => {
      if (data) {
        setCurrentUser(data.signIn)
      }
    },
    [setCurrentUser]
  )

  return (
    <SoloCard>
      <Card title="SIGN IN">
        <ApiForm
          mutation={SIGNIN}
          submitText="SIGN IN"
          afterSubmit={handleSubmit}
        >
          <Input name="email" />
          <Input name="password" type="password" />
        </ApiForm>
      </Card>
      <Link to={`/forgot_password`}>Forgot your password?</Link>
    </SoloCard>
  )
}

export default Login
