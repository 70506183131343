const findAstNodeByKey = (astNode, key) => {
  if (!astNode || !astNode.children) return null
  for (const child of astNode.children) {
    const childKey =
      child.key.value || (child.key.raw && JSON.parse(child.key.raw))
    if (childKey === key) {
      return child.value
    }
  }
  return null
}

export default findAstNodeByKey
