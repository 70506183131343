import React, { useMemo, useRef, useCallback, useState } from "react"
import { Button, Row, Col, Typography } from "antd"

import SchemaTreeView from "./SchemaTreeView"
import JSONSchemaForm from "../../JSONSchemaForm"
import StatusIndicator from "./StatusIndicator"
import { mapFormDataToSchema } from "../../lib/formData"
import getDataWithDefaultValues from "../../lib/getDataWithDefaultValues"

const generateFormData = (normalizedData, schema) => {
  const formData = mapFormDataToSchema({ schema, data: normalizedData })
  const data = getDataWithDefaultValues({ schema, data: formData })
  return data
}

const validateSchema = schemaText => {
  try {
    const schema = JSON.parse(schemaText)
    generateFormData({}, schema)
    return true
  } catch (e) {
    return false
  }
}

const SchemaEditor = () => {
  const cmInstance = useRef()
  const [status, setStatus] = useState(null)
  const schemaTextRef = useRef(localStorage.getItem("schema-text") || "")
  const [schemaText, setSchemaText] = useState(
    localStorage.getItem("valid-schema-text") || ""
  )

  const runValidateSchema = useCallback(schemaText => {
    const isValid = validateSchema(schemaText)
    if (isValid) {
      setStatus(null)
      localStorage.setItem("valid-schema-text", schemaText)
      return true
    }
    setStatus("error")
    return false
  }, [])

  const handleCheckSchema = useCallback(() => {
    runValidateSchema(schemaTextRef.current)
  }, [runValidateSchema])

  const handleChange = useCallback(
    async ({ schemaText }) => {
      localStorage.setItem("schema-text", schemaText)
      schemaTextRef.current = schemaText
      const isValid = runValidateSchema(schemaText)
      if (isValid) {
        setSchemaText(JSON.stringify(JSON.parse(schemaText), null, 2))
      }
    },
    [runValidateSchema]
  )

  const schemaEditorSchema = useMemo(() => {
    return {
      type: "object",
      properties: {
        schemaText: {
          type: "string",
          title: "Form Schema",
          default: "",
          metadata: {
            onInit: cm => {
              cmInstance.current = cm
            },
            height: "calc(100vh - 200px)",
            hideLabel: true,
            component: "CodeEditor",
            containerStyle: {
              overflowY: "auto",
            },
          },
        },
      },
    }
  }, [])

  const handleNodeSelect = useCallback(position => {
    const codeMirrorInstance = cmInstance.current
    if (codeMirrorInstance && position) {
      const { start, end } = position
      codeMirrorInstance.focus()

      // Convert offsets to CodeMirror positions
      const startPos = codeMirrorInstance.posFromIndex(start.offset)
      const endPos = codeMirrorInstance.posFromIndex(end.offset)

      codeMirrorInstance.setSelection(startPos, endPos)
      codeMirrorInstance.scrollIntoView(startPos, 200)
    }
  }, [])

  return (
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <Typography.Title level={4}>
          Schema Editor <StatusIndicator status={status} />{" "}
          <Button.Group>
            <Button onClick={handleCheckSchema} type="default">
              Check Schema
            </Button>
          </Button.Group>
        </Typography.Title>
        <JSONSchemaForm
          hideSave
          schema={schemaEditorSchema}
          onFormChange={handleChange}
          initialState={{ schemaText }}
        />
      </Col>
      <Col span={12}>
        {schemaText ? (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Typography.Title level={4}>Schema Tree</Typography.Title>
              <div
                style={{
                  overflow: "auto",
                  height: "calc(50vh - 110px)",
                }}
              >
                <SchemaTreeView
                  onNodeSelect={handleNodeSelect}
                  schemaText={schemaText}
                />
              </div>
            </Col>
            <Col span={24}>
              <Typography.Title level={4}>Form Preview</Typography.Title>
              <div
                style={{
                  overflow: "auto",
                  height: "calc(50vh - 100px)",
                }}
              >
                <JSONSchemaForm hideSave schema={JSON.parse(schemaText)} />
              </div>
            </Col>
          </Row>
        ) : null}
      </Col>
    </Row>
  )
}

export default SchemaEditor
