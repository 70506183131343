import React, { memo, useCallback } from "react"
import { kebabCase } from "lodash"
import ReactDragListView from "react-drag-listview"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Flex, Space } from "antd"

import DefaultListItems from "./DefaultListItems"
import CollapsedListItems from "./CollapsedListItems"
import { useFormData } from "../../hooks"

const ListsMap = {
  default: DefaultListItems,
  collapse: CollapsedListItems,
}

const ListTitle = props => {
  const { parentSchema, icon } = props
  return (
    <Space>
      {icon ? <FontAwesomeIcon icon={icon} /> : null}
      <span>{parentSchema.title}:</span>
    </Space>
  )
}

const ArrayList = props => {
  const { name, onChange, schema = {}, schema: parentSchema } = props

  const value = useFormData(name)
  const { metadata = {} } = parentSchema
  const {
    icon,
    itemType = "default",
    draggable = true,
    hideLabel = true,
  } = metadata

  const handleDragEnd = useCallback(
    (fromIndex, toIndex) => {
      if (toIndex < 0) return // Ignores if outside designated area
      const items = [...value]
      const item = items.splice(fromIndex, 1)[0]
      items.splice(toIndex, 0, item)
      onChange && onChange(items, "moved", fromIndex, toIndex)
    },
    [onChange, value]
  )

  if (!value?.length) {
    return null
  }

  const List = ListsMap[itemType]

  if (!draggable) {
    return (
      <Flex vertical>
        {!hideLabel && parentSchema.title ? (
          <ListTitle parentSchema={parentSchema} icon={icon} />
        ) : null}
        <List {...props} value={value} />
      </Flex>
    )
  }

  const handleClassName = kebabCase(schema.title) + "-drag-handle"
  const nodeClassName = kebabCase(schema.title) + "-drag-item"
  return (
    <Flex vertical>
      {!hideLabel && parentSchema.title ? (
        <ListTitle parentSchema={parentSchema} icon={icon} />
      ) : null}
      <ReactDragListView
        onDragEnd={handleDragEnd}
        nodeSelector={`.${nodeClassName}`}
        handleSelector={`.${handleClassName}`}
      >
        <List
          {...props}
          value={value}
          nodeClassName={nodeClassName}
          handleClassName={handleClassName}
        />
      </ReactDragListView>
    </Flex>
  )
}

export default memo(ArrayList)
