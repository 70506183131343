import parseJson from "json-to-ast"

export const getFirstTwoLevelsKeys = data => {
  const keys = []
  data.forEach(node => {
    keys.push(node.key)
    if (node.children) {
      node.children.forEach(child => {
        keys.push(child.key)
      })
    }
  })
  return keys
}

export const buildTreeData = (text, traverseNode) => {
  let ast
  try {
    ast = parseJson(text, { loc: true })
  } catch (e) {
    console.error("Invalid JSON:", e)
    return []
  }

  const spec = JSON.parse(text)

  const rootAstNode = ast
  const rootNode = traverseNode(spec, rootAstNode, "root")

  return rootNode ? [rootNode] : []
}
