import React from "react"
import { Checkbox } from "antd"
import { useMutation } from "@apollo/client"

import { useCurrentCustomer } from "@dbai/ui-staples"

import {
  GET_WORKFLOWS,
  PUBLISH_WORKFLOW,
  DELETE_WORKFLOW_PUBLICATION,
} from "queries"

const refetchQueries = [GET_WORKFLOWS]

const PublishCheckbox = props => {
  const [{ id: customerId }] = useCurrentCustomer()
  const {
    row: { published, id: workflowId },
  } = props
  const variables = {
    workflowId,
    customerId,
  }
  const [publish] = useMutation(PUBLISH_WORKFLOW, {
    variables,
    refetchQueries,
  })

  const [deletePublication] = useMutation(DELETE_WORKFLOW_PUBLICATION, {
    variables,
    refetchQueries,
  })

  const handleChange = () => {
    if (published) {
      return deletePublication()
    }
    return publish()
  }

  return <Checkbox checked={published} onChange={handleChange} />
}

export default PublishCheckbox
