import React, { useCallback, useMemo, useState } from "react"
import { Input as FilterInput, Button } from "antd"
import styled from "styled-components"
import { toast } from "react-toastify"
import { faNotebook } from "@fortawesome/pro-solid-svg-icons"
import { useQuery, useMutation } from "@apollo/client"

import { FAMemo, Input, Spinner, ErrorMessage } from "@dbai/ui-staples"

import { GET_PUBLISHED_WORKFLOWS, CLONE_WORKFLOW_PUBLICATION } from "queries"

const mapNotebooks = data => {
  if (!data?.workflowPublications) {
    return []
  }
  return (
    data.workflowPublications
      .filter(wp => {
        return wp.workflow
      })
      .map(wp => {
        return workflowToOption({
          id: wp.id,
          name: wp.workflow.name,
        })
      }) || []
  )
}

const workflowToOption = ({ id, name }) => ({
  label: name,
  value: { id, name },
})

const Flow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
`

const OptionFlow = styled(Flow)`
  padding: 5px;
  cursor: pointer;
  border: ${props => (props.isSelected ? "1px solid #ffffff" : "none")};
  background-color: ${props => (props.isSelected ? "#9CADB5" : "transparent")};

  &:hover {
    background-color: #deebff; // Taken from react-select
  }
`

const OptionText = styled.span`
  margin-left: 10px;
  color: ${props => (props.isSelected ? "#ffffff" : props.theme.text)};
`
const NotebookIcon = styled(FAMemo)`
  color: ${props => (props.isSelected ? "#ffffff" : props.theme.text)};
`

const Option = props => {
  const { data, onSelect, isSelected } = props

  return (
    <OptionFlow onClick={() => onSelect(data)} isSelected={isSelected}>
      <NotebookIcon icon={faNotebook} isSelected={isSelected} fixedWidth />
      <OptionText isSelected={isSelected}>{data.label}</OptionText>
    </OptionFlow>
  )
}
const getOptionKey = opt => {
  const { value = {} } = opt
  return value.id || `${opt.label}${value.color}`
}

const OptionsFilter = props => {
  const { filter, onChange } = props

  return (
    <FilterInput.Search
      placeholder="Filter Templates"
      onChange={onChange}
      value={filter}
    />
  )
}

const PublicationSelect = props => {
  const { filtered, selectOptions, onSelect, value } = props
  const [filter, setFilter] = useState("")
  const handleFilterChange = e => setFilter(e.target.value)
  const filteredOptions = useMemo(() => {
    if (!filter) return selectOptions
    const filterOptions = opt =>
      opt.label.toLowerCase().includes(filter.toLowerCase())
    return {
      ...selectOptions,
      options: selectOptions.options.filter(filterOptions),
    }
  }, [filter, selectOptions])

  const renderOption = useCallback(
    opt => {
      const key = getOptionKey(opt)
      return (
        <Option
          key={key}
          data={opt}
          onSelect={onSelect}
          isSelected={value && value.value === opt.value}
        />
      )
    },
    [onSelect, value]
  )

  if (filtered) {
    return (
      <>
        <OptionsFilter
          placeholder="Filter Workflow Templates"
          onChange={handleFilterChange}
          value={filter}
        />
        {filteredOptions.options.map(renderOption)}
      </>
    )
  }

  return <>{selectOptions.options.map(renderOption)}</>
}

const LibraryPicker = props => {
  const { refetchQueries, customerId, onClose } = props
  const [name, setName] = useState("")
  const [selectedOption, setSelectedOption] = useState(null)
  const { data, loading, error } = useQuery(GET_PUBLISHED_WORKFLOWS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  })

  const [cloneWorkflow] = useMutation(CLONE_WORKFLOW_PUBLICATION, {
    onCompleted: () => {
      if (onClose) {
        onClose()
      }
    },
    onError: error => {
      toast.error(error.message)
    },
    refetchQueries,
  })

  const handleNameChange = useCallback(e => {
    setName(e.target.value)
  }, [])

  const options = useMemo(() => mapNotebooks(data), [data])
  const selectOptions = useMemo(
    () => ({
      label: "Select a Workflow",
      options,
    }),
    [options]
  )

  const handleSelect = useCallback(option => {
    setSelectedOption(option)
  }, [])

  const handleClone = useCallback(async () => {
    if (selectedOption) {
      try {
        await cloneWorkflow({
          variables: {
            customerId: customerId,
            workflowPublicationId: selectedOption.value.id,
            name: name || `${selectedOption.value.name} (clone)`,
          },
        })
      } catch (error) {
        console.error("Error cloning workflow", error)
      }
    } else {
      toast.error("Please select a workflow to clone")
    }
  }, [cloneWorkflow, customerId, name, selectedOption])

  if (loading) return <Spinner />
  if (error) return <ErrorMessage error={error} />

  return (
    <>
      <Input name="Name" value={name} onChange={handleNameChange} />
      <PublicationSelect
        filtered
        selectOptions={selectOptions}
        onSelect={handleSelect}
        value={selectedOption}
      />
      <Button onClick={handleClone}>Clone Workflow</Button>
    </>
  )
}

const PublishedWorkflowPicker = props => {
  const { customer, refetchQueries, onClose } = props

  return (
    <LibraryPicker
      customerId={customer.id}
      refetchQueries={refetchQueries}
      onClose={onClose}
    />
  )
}

export default PublishedWorkflowPicker
